import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';
import moment from 'moment';
import DateFormat from '../shared/DateFormat'
import MoneyFormat from '../shared/MoneyFormat';
import { httpGet, httpPost } from '../shared/Config';

function SpeiSubscriptionModal({isOpen, onCancel, id}) {

    const [working, setWorking] = useState(false);
    const [paymentSPEI, setPaymentSPEI] = useState({
        amount: 0,
        reference: 0,
        createdAt: ''
    });
    const [fetchComplete, setFetchComplete] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [error, setError] = useState(false);

    const [reactivationComplete, setReactivationComplete] = useState(false);

    function handleDismiss(){
        onCancel();
    }

    useEffect(() => {
        setWorking(true);
        async function getInfo() {
            const getPaymentStatus = await httpGet(`/payments/${id}/getSpeiSubStatus`);
            // if INACTIVE_NO_RENEWAL, extra button to activate
            if(getPaymentStatus.data.data) {
                if(getPaymentStatus.data.data.status === "error") {
                    setError(true);
                } else {
                    setSubscriptionStatus(getPaymentStatus.data.data.status);
                    if(getPaymentStatus.data.data.status !== "INACTIVE_NO_RENEWAL"){
                        const responsePaymentInfo = await httpGet(`/payments/${id}/speiPaymentInfo`);
                        if(responsePaymentInfo.data.data) {
                            if(responsePaymentInfo.data.data.result === "error") {
                                setError(true);
                            } else if(responsePaymentInfo.data.data.result === "no_latest") {
                            } else {
                                setPaymentSPEI(responsePaymentInfo.data.data);
                            }
            
                        }
                    }
                    setWorking(false);
                    setFetchComplete(true);
                }
            }
        }

        if(isOpen) {
            getInfo();
        }
    }, [id, isOpen])

    function generatePayment() {
        setWorking(true);
        setFetchComplete(false);
        setError(false);
        async function generatePayment() {
            const responseNewSpeiPayment = await httpGet(`/payments/${id}/generateNewSpeiPayment`);
            if(responseNewSpeiPayment) {
                if(responseNewSpeiPayment.data.data.result === "error") {
                    setError(true);
                } else {
                    setPaymentSPEI(responseNewSpeiPayment.data.data);
                }
            }
            setWorking(false);
            setFetchComplete(true);
        }

        generatePayment();
    }

    function reactivateInactiveNoRenewal() {
        setWorking(true);
        setFetchComplete(false);
        setError(false);
        async function generatePayment() {
            const reactivateSubscription = await httpGet(`/payments/${id}/reactivateInactiveNoRenewal`);
            if(reactivateSubscription.data.data) {
                if(reactivateSubscription.data.data.result === "error") {
                    setError(true);
                } else {
                    if(reactivateSubscription.data.data.status === "success"){
                        setReactivationComplete(true);
                        const getPaymentStatus = await httpGet(`/payments/${id}/getSpeiSubStatus`);
                        // if INACTIVE_NO_RENEWAL, extra button to activate
                        if(getPaymentStatus.data.data) {
                            if(getPaymentStatus.data.data.status === "error") {
                                setError(true);
                            } else {
                                setSubscriptionStatus(getPaymentStatus.data.data.status);
                                const responsePaymentInfo = await httpGet(`/payments/${id}/speiPaymentInfo`);
                                if(responsePaymentInfo.data.data) {
                                    if(responsePaymentInfo.data.data.result === "error") {
                                        setError(true);
                                    } else if(responsePaymentInfo.data.data.result === "no_latest") {
                                    } else {
                                        setPaymentSPEI(responsePaymentInfo.data.data);
                                    }
                                }
                                setWorking(false);
                                setFetchComplete(true);
                            }
                        } else {
                            setError(true);
                        }
                    }
                }
            }
        }

        generatePayment();
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <ModalHeader>    
                    Datos de suscripción
                </ModalHeader>
                <ModalBody>
                    {
                        fetchComplete ? <React.Fragment>
                            {
                                subscriptionStatus === "INACTIVE_NO_RENEWAL" ? <React.Fragment>
                                    <p>Esta suscripción fue desactivada por el usuario. Se requiere activación manual para continuar.
                                        Al reactivar la suscripción, empezara a recibir notificaciones de nuevo.</p>            
                                    <Button disabled={working} color="primary" onClick={reactivateInactiveNoRenewal}>
                                        Reactivar suscripción
                                    </Button>
                                </React.Fragment> : <React.Fragment>
                                    {
                                        paymentSPEI.reference !== 0 ? <React.Fragment>
                                            <p>Este el pago que debe completarse para activar esta suscripción. Antes de hacer esta transferencia,
                                                asegúrese que no sea un pago que haya efectuado ya. En caso de ser así y su departamento sigue inactivo,
                                                contáctenos.</p>
                                            <p>Su departamento debe estar funcional en este momento. Tiene tres dias para realizar su pago.</p>
                                            Cantidad a pagar: <MoneyFormat>{paymentSPEI.amount}</MoneyFormat>
                                            <br/>
                                            Referencia: {paymentSPEI.reference}
                                            <br/>
                                            Fecha de creacion de pago: <DateFormat>{moment(paymentSPEI.createdAt)}</DateFormat>
                                            <br/>
                                            Fecha limite de pago: <DateFormat>{moment(paymentSPEI.createdAt).add(3,'days')}</DateFormat>
                                            <br/>
                                        </React.Fragment> : <React.Fragment>
                                            <p>No se encontró ningún pago relacionado con esta suscripción. A continuación puede generar un pago para este departamento,
                                                tenga en mente que al completar el pago, el departamento se reactivará.</p>        
                                                
                                            <p>Al dar click en el boton de abajo, este departamento no podra cambiarse a una subscripcion con STRIPE.</p>    
                                            <Button disabled={working} color="primary" onClick={generatePayment}>
                                                Activar con SPEI y generar pago
                                            </Button>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            }

                            {
                                error ? <React.Fragment>
                                    <p style={{fontWeight: 'bold', color: 'red'}}>Error al obtener el pago relacionado a este departamento. Intente más tarde.</p>
                                </React.Fragment> : null
                            }
                        </React.Fragment> : <React.Fragment>
                            Espere un momento...
                        </React.Fragment>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button disabled={working} color="secondary" onClick={handleDismiss}>
                        Cerrar
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
export default SpeiSubscriptionModal;