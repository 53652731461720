import React from 'react';
import Section from './Section'

function SectionList({children, collection, noData}) {
    return (    
        collection && collection.length > 0 ? 
            children.map(c => <Section key={c.key}>{c}</Section>)
            : 
            <Section>
                <div className="NoData">
                    {noData}
                </div>     
            </Section>                    
    )
}

export default SectionList