import React from 'react';

function EmailRegistration() {

    return(
        <div className="container">
            <p>Correo electrónico registrado. Puede cerrar esta pestaña.</p>
        </div>
    )
}

export default EmailRegistration