import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form } from 'reactstrap';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import StripeTimestampsCheckout from './StripeTimestampsCheckout';

const stripeObject = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function StripeTimestampsModal({isOpen, months, discount, buyState, onCancel, onStripeApproved}) {

    const [working, setWorking] = useState(false);
    const [succeeded, setSucceeded] = useState(false);

    function handleDismiss(){
        if(succeeded) {
            onStripeApproved();
        } else {
            onCancel();
        }
    }

    function onSucceeded() {
        setSucceeded(true);
    }

    function onWorking() {
        setWorking(true);
    }

    function onProccesEnd() {
        setWorking(false);
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <ModalHeader>    
                    Pago de sellos NOM151
                </ModalHeader>
                <ModalBody>
                    <Elements stripe={stripeObject}>
                        <StripeTimestampsCheckout buyState={buyState} months={months} discount={discount} onSucceeded={onSucceeded} onWorking={onWorking} onProccesEnd={onProccesEnd} />
                    </Elements>
                </ModalBody>
                <ModalFooter>
                    {/* <Button type="submit" disabled={!working} color="primary">Pagar</Button> */}
                    <Button disabled={working} color="secondary" onClick={handleDismiss}>
                        {
                            succeeded ? <React.Fragment>
                                Cerrar
                            </React.Fragment> : <React.Fragment>
                                Cancelar
                            </React.Fragment>
                        }
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}
export default StripeTimestampsModal;