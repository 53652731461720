import React, {useState, useEffect} from 'react';
import {httpGet} from '../shared/Config';
import MoneyFormat from '../shared/MoneyFormat';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter, Table} from 'reactstrap';

function CorporativoInfoModal({isOpen, onClose}) {

    const [documentPacks, setDocumentPacks] = useState([]);
    const [timestampsCost, setTimestampsCost] = useState(0);
    useEffect(function() {
        async function fetchDocumentPacks() {
            try {
                
                const responseTimestamps = await httpGet('/timestampsCost');
                const response = await httpGet('/documentPacks');

                setTimestampsCost(responseTimestamps.data.perDocument);

                let length = response.data.length;
                let index = 0;

                for(let range of response.data) {
                    if(index === 0)
                        range.type = "start";
                    else if(index !== length - 1)
                        range.type = "middle";
                     else 
                        range.type = "end";

                    index++;
                }

                setDocumentPacks(response.data)
            } catch(e) {
                console.error(e);
                setDocumentPacks([]);
            }
        }

        if(isOpen) {
            fetchDocumentPacks();
        }        

    }, [isOpen])

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Plan 'ON DEMAND'
            </ModalHeader>
            <ModalBody>
                <p>
                    Con el plan 'ON DEMAND' puedes utilizar el API para conectar Firmamex a tus propias aplicaciones, 
                    no pagas ni mensualidad ni iniciadores adicionales, <span className="Bold">pero los documentos se compran por paquete.</span>
                </p>
                <Table>
                    <tbody>        
                        <tr>
                            <th scope="row">Iniciadores</th>
                            <td>Ilimitados</td>
                        </tr>
                        <tr>
                            <th scope="row">Documentos</th>
                            <td>Por paquete</td>
                        </tr>
                        <tr>
                            <th scope="row">API, Autómata</th>
                            <td>Incluido</td>
                        </tr>
                    </tbody>
                </Table>
                <br/>
                <p>Paquetes de documentos</p>
                <Table>
                    <thead>                        
                        <tr>
                            <th>Rango de documentos</th>
                            <th>Precio por documento</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            documentPacks.map(dp => (
                                <tr key={dp.id}>      
                                        {
                                            dp.type === "start" ? <React.Fragment>
                                                <td>1 - {dp.documents}</td>
                                            </React.Fragment> : <React.Fragment>
                                                {
                                                    dp.type === "middle" ? <React.Fragment>
                                                        <td>{dp.documents} - {dp.rangeDuo}</td>
                                                    </React.Fragment> : <React.Fragment>
                                                        <td>{dp.documents}+</td>
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>
                                        }
                                    <td>
                                        <MoneyFormat>{dp.perDocument + timestampsCost}</MoneyFormat>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClose}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default CorporativoInfoModal