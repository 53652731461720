import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faBuilding, faUser, faUserFriends } from '@fortawesome/free-solid-svg-icons'
//import { ReactComponent as SpeiLogo } from '../icons/Spei_Logo.svg'
//import { ReactComponent as StripeLogo } from '../icons/Stripe_Logo.svg'
import SpeiLogo from '../icons/Spei_Logo2.png'
import StripeLogo from '../icons/Stripe_Logo2.png'
import {Label, FormGroup, Input} from 'reactstrap';

function PaymentIcon({name, value, selected, onClick}) {
    function handleClick() {
        onClick(value);
    }

    //function handleMoreInfoClick() {
    //    onMoreInfoClick(value);
   // }

    return (
        <div className={`column PlanIcon ${selected ? 'Selected' : ''}`} onClick={handleClick}>
            {
                name === "Stripe" ? <React.Fragment>
                    <img src={StripeLogo} alt="stripelogo" />
                    <div>Pago con tarjeta</div>
                </React.Fragment> : null
            }
            {
                name === "SPEI" ? <React.Fragment>
                    <img src={SpeiLogo} alt="speilogo" />
                    <div>SPEI</div>
                </React.Fragment> : null
            }
            {/*<div 
                style={{color:'#007bff'}}
                onClick={handleMoreInfoClick}>Más info</div>*/}
        </div>
    )
}

function SelectPaymentType({value, subValue, type, action, onChange, pendingPayments, askFacturacion}) {

    const [selected, setSelected] = useState({name:value, sub: subValue})
    const [stripeSelected, setStripeSelected] = useState(false);

    function handleClick(event) {
        if(event.target.value === "Stripe0") {
            setStripeSelected(true);
        } else {
            setStripeSelected(false);
        }

        setSelected({...selected, name: event.target.value});
    }

    function subTypeChanged(sub) {
        return function(event) {
            setSelected({...selected, [sub]: event.target.value});
        }
    }

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <React.Fragment>
            <h5>Seleccione una forma de pago</h5>
            <div className="column" >
                {/*<PaymentIcon icon={faUser} 
                    name="Stripe" 
                    selected={'Stripe' === selected.name}
                    value="Stripe"
                    onClick={handleClick}></PaymentIcon>
                <PaymentIcon icon={faBuilding} 
                    name="SPEI" 
                    selected={'SPEI' === selected.name}
                    value="SPEI"
                    onClick={handleClick}></PaymentIcon>*/}
                {
                    action === "CONSUMABLE" ? <React.Fragment>
                        <FormGroup tag="fieldset">
                            <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                                <Input disabled={pendingPayments || askFacturacion} name="radio1" type="radio" value="SPEI" checked={"SPEI" === selected.name} onChange={handleClick} />{' '}
                                <Label check>
                                    {/*<img style={{maxHeight:'25px'}} src={SpeiLogo} alt="stripelogo" />*/}
                                    Transferencia SPEI - Un solo pago (10% de descuento)
                                </Label>
                            </FormGroup>    
                            {
                                "SPEI" === selected.name ? <React.Fragment>
                                    <div style={{marginBottom: '2%'}}>
                                        En caso de usar por <b>SPEI</b>, tendrá acceso a <b>10%</b> de sus {type} (o un máximo de <b>50</b>) hasta que realice el pago.
                                    </div>                                
                                </React.Fragment> : null
                            }  
                            <FormGroup style={{marginLeft: '5%', marginBottom: '3%'}} check>
                                <Input disabled={pendingPayments || askFacturacion} name="radio1" type="radio" value="Stripe0" checked={"Stripe0" === selected.name}  onChange={handleClick} />{' '}
                                <Label check>
                                    {/*<img style={{maxHeight:'25px'}} src={StripeLogo} alt="stripelogo" />*/}
                                    Pago con tarjeta (descuento puede variar)
                                </Label>
                            </FormGroup>
                            {  
                                stripeSelected ? <React.Fragment>
                                    <FormGroup>
                                        <Input disabled={pendingPayments || askFacturacion} type="select" value={selected.sub} onChange={subTypeChanged('sub')} required>
                                            <option value="Stripe0">Un solo pago (6% de descuento)</option>
                                            <option value="Stripe6">6 meses (3% de descuento)</option>
                                            <option value="Stripe12">12 meses (Sin descuento)</option>
                                        </Input>
                                    </FormGroup>    
                                </React.Fragment> : null
                            }
                            {/*<FormGroup style={{marginLeft: '5%', marginBottom: '1%'}} check>
                                <Input name="radio1" type="radio" value="Stripe6" checked={"Stripe6" === selected.name}  onChange={handleClick} />{' '}
                                <Label check>
                                    <img style={{maxHeight:'25px'}} src={StripeLogo} alt="stripelogo2" /> 6 meses (3% de descuento)
                                </Label>
                            </FormGroup>           
                            <FormGroup style={{marginLeft: '5%',marginBottom: '1%'}} check>
                                <Input name="radio1" type="radio" value="Stripe12" checked={"Stripe12" === selected.name}   onChange={handleClick} />{' '}
                                <Label check>
                                    <img style={{maxHeight:'25px'}}  src={StripeLogo} alt="stripelogo3" /> 12 meses (Sin descuento)
                                </Label>
                            </FormGroup>*/}
                        </FormGroup>
                    </React.Fragment> : <React.Fragment>
                        <FormGroup tag="fieldset">
                            <FormGroup style={{marginLeft: '5%', marginTop: '2%', marginBottom: '1%'}} check>
                                <Input name="radio1" type="radio" value="SPEI" checked={"SPEI" === selected.name} onChange={handleClick} />{' '}
                                <Label check>
                                    Transferencia SPEI
                                </Label>
                            </FormGroup>
                            <FormGroup style={{marginLeft: '5%', marginBottom: '3%'}} check>
                                <Input name="radio1" type="radio" value="Stripe0" checked={"Stripe0" === selected.name}  onChange={handleClick} />{' '}
                                <Label check>
                                    {/*<img style={{maxHeight:'25px'}} src={StripeLogo} alt="stripelogo" />*/}
                                    Pago con tarjeta
                                </Label>
                            </FormGroup>
                        </FormGroup>
                    </React.Fragment>
                }

            </div>
        </React.Fragment>
    )
}

export default SelectPaymentType
