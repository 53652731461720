import React, {useState, useEffect, useRef} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, Spinner} from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat';
import ConfirmationModal from '../shared/ConfirmationModal';
import StripeTimestampsModal from '../payments/StripeTimestampsModal';
import SelectPaymentType from '../payments/SelectPaymentType';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import EmailNoticeSPEI from '../payments/EmailNoticeSPEI';

const defaultState = {    
    totalGlobalTimestamps: 0,
    monthly: false,
    paymentType: 'SPEI',
    sub: 'Stripe0'
}

function BuyGlobalTimestampsModal({isOpen, onCancel, onSaved, companyActive, pendingPayments}) {

    const [processingPayment, setProcessingPayment] = useState(false);

    const [working, setWorking] = useState(false);
    const [calcWorking, setCalcWorking] = useState(false);
    //const [checked, setChecked] = useState(false);
    
    const [minimumNotReached, setMinimumNotReached] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false); 

    const [minimumCost, setMinimumCost] = useState(10); // SPEI minimum is $10 pesos
    const [months, setMonths] = useState(6);
    const [discount, setDiscount] = useState(0);

    const [paymentModal, setPaymentModal] = useState({
        open: false
    });

    const interval = useRef(setTimeout(() => {}, 0));

    const [buyState, setBuyState] = useState({
        totalGlobalTimestamps: 0,
        monthly: false,
        paymentType: 'SPEI',
        sub: 'Stripe0'
    });

    const [resultState, setResultState] = useState({
        precioUnitario: 0,
        precioUnitarioMasIva: 0,
        precioPaquete: 0,
        precioPaqueteMasIva: 0,
        precioTotal: 0,
        precioTotalIva: 0,
        mensualidades: 0,
        mensualidadesMasIva: 0,
        pagoUnaSolaExhibicion: 0,
        pagoUnaSolaExhibicionMasIva: 0
    });
        
    const [noticeModal, setNoticeModal] = useState({
        open: false
    });

    function handleBuyStateChange(name) {
        return function(event) {
            let value;
            if(event.target.value >= 0 && event.target.value.length < 10) {
                value = event.target.value;
                setBuyState({...buyState, [name]:parseInt(value)});
            }
        }
    }

    /*function handleSliderChange(value) {
        setVirtualTotalTimestamps(value);
        let packageAmount = calculatePackageAmount(value);
        setDisplayTotalTimestamps(packageAmount);
    }
    
    function handleSliderChangeEnd(value) {
        let packageAmount = calculatePackageAmount(value);
        setBuyState({...buyState, "totalGlobalTimestamps":parseInt(packageAmount)});
    }

    function calculatePackageAmountType(value, type) {
        if(!type) {
            switch(value) 
            {
                case 1: {
                    return 50;
                }
                case 2:{
                    return 100;
                }
                case 3: {
                    return 500;
                }
                case 4: {
                    return 1000;
                }
                case 5: {
                    return 2500;
                }
                case 6: {
                    return 5000;
                }
                case 7: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        } else {
            switch(value) 
            {
                case 1: {
                    return 1000;
                }
                case 2:{
                    return 2500;
                }
                case 3: {
                    return 5000;
                }
                case 4: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        }
    }

    function calculatePackageAmount(value) {
        if(!buyState.monthly) {
            switch(value) 
            {
                case 1: {
                    return 50;
                }
                case 2:{
                    return 100;
                }
                case 3: {
                    return 500;
                }
                case 4: {
                    return 1000;
                }
                case 5: {
                    return 2500;
                }
                case 6: {
                    return 5000;
                }
                case 7: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        } else {
            switch(value) 
            {
                case 1: {
                    return 1000;
                }
                case 2:{
                    return 2500;
                }
                case 3: {
                    return 5000;
                }
                case 4: {
                    return 10000;
                }
                default: {
                    return 0;
                }
            }
        }
    }*/

    /*function updateOrgsValues(orgId) {
        return function(event) {
            let value;
            if(event.target.value.length < 10) {
                value = event.target.value;
                let newArray = buyState.orgsState;
                newArray[orgId].documentsAmount = value;
                setBuyState({...buyState, orgsState:newArray});
            }
        }
    }*/

    /*function handleMonthlyPaymentsChange(name) {
        return function(event) {
            let value = !buyState.monthly;

            if(value) {
                setMinimumValue(1000);
                setSliderMax(4);

                if(virtualTotalTimestamps < 4) {
                    setVirtualTotalTimestamps(1);
                    setDisplayTotalTimestamps(1000);
                    setBuyState({...buyState, [name]:value, "totalGlobalTimestamps": 1000});
                } else {
                    let newVirtualPosition = virtualTotalTimestamps - 3;
                    setVirtualTotalTimestamps(newVirtualPosition);
                    let packageAmount = calculatePackageAmountType(newVirtualPosition, value);
                    setDisplayTotalTimestamps(packageAmount);
                    setBuyState({...buyState, [name]:value, "totalGlobalTimestamps": packageAmount});
                }
            } else {
                setMinimumValue(50);
                setSliderMax(7);

                let newVirtualPosition = virtualTotalTimestamps + 3;
                setVirtualTotalTimestamps(newVirtualPosition);

                let packageAmount = calculatePackageAmountType(newVirtualPosition, value);
                setDisplayTotalTimestamps(packageAmount);
                setBuyState({...buyState, [name]:value, "totalGlobalTimestamps": packageAmount});
            }
        }
    }*/


    /*useEffect(() => {

        let orgsStateComplete = [];

        orgs.map((org) => {
            let o = {};
            o[org.id] = {id: org.id, name: org.name, documentsAmount: 0};
            orgsStateComplete.push(o);
            return null;
        });

        setBuyState((currentState) => {
            return {...currentState, orgsState: orgsStateComplete}
        });

    }, [orgs]);*/

    useEffect(() => {
        if(buyState.totalGlobalTimestamps > 0) {
            //setUserInput(true);

            /*let minimumSet = 0;

            if(buyState.monthly) {
                minimumSet = 1000;
                setMinimumValue(1000);
            } else {
                minimumSet = 50;
                setMinimumValue(50);
            }*/

            //if(buyState.totalGlobalTimestamps  >= minimumSet) {
                
            //setMininumTimestamps(true);
            setCalcWorking(true);
            interval.current = setTimeout(() => {
                async function calculate() {
                    try {
                        const response = await httpPost('/calculateTotalTimestamps', {
                            buyState
                        });
        
                        if(response) {
                            setResultState(previousState => {
                                return {
                                    ...previousState,
                                    precioUnitario: response.data.data.precioUnitario,
                                    precioUnitarioMasIva: response.data.data.precioUnitarioIva,
                                    precioPaquete: response.data.data.precioPaquete,
                                    precioPaqueteMasIva: response.data.data.precioPaqueteIva,
                                    precioTotal: response.data.data.total,
                                    precioTotalIva: response.data.data.totalIva,
                                    mensualidades: response.data.data.mensualidad,
                                    mensualidadesMasIva: response.data.data.mensualidadIva,
                                    pagoUnaSolaExhibicion: response.data.data.soloPago,
                                    pagoUnaSolaExhibicionMasIva: response.data.data.soloPagoIva
                                }
                                
                            })
                        }

                        
                        checkMininumThreshold( response.data.data.totalIva);
                        /*if(buyState.totalGlobalDocuments < 1000) {
                            setMonthlyMininumNotReached(true);
                        } else {
                            setMonthlyMininumNotReached(false);
                        }
            
                        if(buyState.totalGlobalDocuments < 50) {
                            setMininumNotReached(true);
                        } else {
                            setMininumNotReached(false);
                        }*/

                        //setChecked(true);
                        setCalcWorking(false);
                    } catch(e) {
                    }
                }
        
                calculate();
            }, 1000);
            /*} else {
                setCalcWorking(false);
                setMininumTimestamps(false);
            }*/
        } else {
            setResultState(previousState => {
                return {
                    ...previousState,
                    precioUnitario: 0,
                    precioUnitarioMasIva: 0,
                    precioPaquete: 0,
                    precioPaqueteMasIva: 0,
                    precioTotal: 0,
                    precioTotalIva: 0,
                    mensualidades: 0,
                    mensualidadesMasIva: 0,
                    pagoUnaSolaExhibicion: 0,
                    pagoUnaSolaExhibicionMasIva: 0
                }
            });
            //setMininumNotReached(true);
            //setMonthlyMininumNotReached(true);
        }

        return () => {
            clearTimeout(interval.current); 
        };
    }, [buyState]);

    function payItem(event) {
        event.preventDefault();
        event.stopPropagation();
        if(buyState.paymentType.includes("Stripe")) {
            setPaymentModal({...paymentModal, open: true});
        } else {
            handleSpeiPay();
        }
    }

    function modalClose() {
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false });
        setPaymentModal({...paymentModal, open: false});
        //setChecked(false);
        //setUserInput(false);
    }

    function stripeApproved() {
        //setChecked(false);
        //setUserInput(false);
        //setBuyState({...buyState, totalGlobalDocuments:0, monthly: false});
        setPaymentModal({...paymentModal, open: false}); // message confirmation
        setBuyState(defaultState);
        onSaved();
    }

    useEffect(() => { 
        async function processSpeiPayment() {
            try {            
                const response = await httpPost(`/orgs/globalTimestamps`, {
                    buyState
                })
                const {success, error} = response.data;
                if(success) {
                    setNoticeModal({...noticeModal, open: true});
                    //setShowConfModal(false);
                    //setBuyState(defaultState);
                    //onSaved();
                } else {
                    console.log(error);
                }
                setWorking(false);
                setProcessingPayment(false);
            } catch(e) {
                setWorking(false);
                setProcessingPayment(false);
            }

            /*setVirtualTotalTimestamps(1);
            let packageAmount = calculatePackageAmountType(1, false);
            setDisplayTotalTimestamps(packageAmount);*/
        }

        if(processingPayment){
            processSpeiPayment();
        }
    }, [processingPayment])

    function handleSpeiPurchaseClick(){
        setProcessingPayment(true);
    }

    function noticeClose() {
        setBuyState(defaultState);
        setShowConfModal(false);
        setNoticeModal({...noticeModal, open: false});
        onSaved();
    }

    /*async function handleSpeiPurchase() {
        //setProcessingPayment(true);
        try {            
            const response = await httpPost(`/orgs/globalTimestamps`, {
                buyState
            })
            const {success, error} = response.data;
            if(success) {
                setShowConfModal(false);
                setBuyState(defaultState);
                onSaved();
            } else {
                console.log(error);
            }
            setWorking(false);
            setProcessingPayment(false);
        } catch(e) {
            setWorking(false);
            setProcessingPayment(false);
        }
    }*/

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
    }

    function handleSpeiPay(event) {
        setWorking(true);
        setShowConfModal(true);
    }

    function handleCancel() {
        //setChecked(false);
        //setUserInput(false);
        setBuyState(defaultState);

        //setMinimumValue(50);
        //setSliderMax(7);
        //setDisplayTotalTimestamps(50);
        //setVirtualTotalTimestamps(1);

        onCancel();
    }

    function handlePaymentTypeChange(type) {
        //setPaymentType(type);
        let monthly = false;
        let minimum = 10;
        let months = 6;
        let discount = 0;

        let paymentTypeCheck = "SPEI";

        switch(type.name){
            case "SPEI":
                discount = 10;
                break;
            case "Stripe0":
                switch (type.sub) {
                    case "Stripe0": 
                        paymentTypeCheck = "Stripe0";
                        discount = 6;
                        break;
                    case "Stripe6":
                        paymentTypeCheck = "Stripe6";
                        discount = 3;
                        minimum = 600;
                        monthly = true;
                        break;
                    case "Stripe12": 
                        paymentTypeCheck = "Stripe12";
                        minimum = 1200;
                        monthly = true;
                        months = 12;
                        break;
                    default:
                        break;
                }
                break;
            default:
                break;
        }

        checkMininumThreshold(resultState.precioTotalIva);
        setMinimumCost(minimum);
        setMonths(months);
        setDiscount(discount);

        setBuyState({...buyState, paymentType:paymentTypeCheck, monthly: monthly});
    }

    function checkMininumThreshold(totalIva) {
        if(totalIva < minimumCost && totalIva !== 0) {
            setMinimumNotReached(true);
        } else {
            setMinimumNotReached(false);
        }
    }


return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <Form onSubmit={payItem}>
                    <ModalHeader>
                        Compra de sellos NOM151
                    </ModalHeader>
                    <ModalBody>     
                        <div>
                            {
                                pendingPayments ? <React.Fragment>
                                    <div style={{marginBottom:'2%', fontWeight: 'bold', color: 'red'}}>Hay un pago pendiente de compra de sellos NOM151. No podrá comprar más sellos NOM151 hasta que realice el pago.</div>
                                </React.Fragment> : null
                            }
                            <div style={{marginBottom:'2%'}}><b>Todos los precios tienen IVA incluido.</b> Los sellos NOM151 que compre expiran <b>15</b> meses después de su compra.</div>
                            <div>Las compras a meses tan solo pueden ser efectuadas con <b>tarjeta de crédito (Stripe).</b></div>
                        </div>
                        <FormGroup tag="fieldset" >
                            <FormGroup>
                                <Label for="totalGlobalTimestamps">Cantidad de sellos NOM151:</Label> 
                                <Input disabled={pendingPayments} type="number" id="totalGlobalTimestamps" value={buyState.totalGlobalTimestamps} onChange={handleBuyStateChange('totalGlobalTimestamps')} required/>
                                {/*<b style={{marginLeft: '5px'}}>{displayTotalTimestamps}</b>
                                <Slider 
                                    value={virtualTotalTimestamps}
                                    onChange={handleSliderChange}
                                    onAfterChange={handleSliderChangeEnd}
                                    min={sliderMin}
                                    max={sliderMax} 
                                    dots />*/}
                            </FormGroup>
                            {/*
                                !minimumTimestamps && userInput ? <React.Fragment>
                                    <p style={{fontWeight: 'bold', color: 'red'}}>La compra mínima debe de ser de al menos {minimumValue} sellos NOM151</p>
                                </React.Fragment> : null
                                */}                            
                            {
                                !companyActive ? <React.Fragment>
                                    <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor, contáctenos para más información.</p>
                                </React.Fragment> : null
                            }
                            {
                                calcWorking ? <React.Fragment>
                                { /* calcWorking */}
                                <div className="d-flex justify-content-center">
                                    <Spinner color="primary" />
                                    </div>
                                </React.Fragment> : null
                            }
                            {/*<MoneyFormat>{resultState.precioUnitario}</MoneyFormat> - Con IVA:  */}
                            Precio unitario: <MoneyFormat>{resultState.precioUnitarioMasIva}</MoneyFormat>
                            <br/>
                        </FormGroup>
                        {/*<FormGroup check>
                            <Label check>
                                <Input disabled={calcWorking || working || !companyActive } type="checkbox" id="monthly" value={buyState.monthly} checked={buyState.monthly} onChange={handleMonthlyPaymentsChange('monthly')} />{' '}
                                {
                                    calcWorking || working || !companyActive ? <React.Fragment> 
                                        <p style={{color: '#DADADA'}}>Pagar en mensualidades</p>
                                    </React.Fragment> : <React.Fragment>
                                        <p>Pagar en mensualidades</p>
                                    </React.Fragment>
                                }
                            </Label>
                            </FormGroup>*/}
                        {/*{
                            !calcWorking && checked && mininumNotReached ? <React.Fragment>
                                <br/>
                                <p style={{fontWeight: 'bold', color: 'red'}}>La compra mínima debe de ser de al menos 10 sellos NOM151</p>
                            </React.Fragment> : null
                        }*/}
                        <div>
                            <SelectPaymentType value={buyState.paymentType} subValue={buyState.sub} action="CONSUMABLE" type="sellos NOM151" onChange={handlePaymentTypeChange} pendingPayments={pendingPayments} />
                        </div>  
                        <FormGroup>
                        Precio total: <MoneyFormat>{resultState.precioTotalIva}</MoneyFormat>
                            {
                                buyState.monthly ? <React.Fragment>
                                    <br/>
                                    {/* <MoneyFormat>{resultState.mensualidades}</MoneyFormat> - Con IVA: */}
                                    {/*<MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat>  */}
                                    Mensualidades: <MoneyFormat>{resultState.mensualidadesMasIva}</MoneyFormat> 
                                    <br/>
                                </React.Fragment> : null 
                            }
                            {
                                minimumNotReached ? <React.Fragment>
                                    <br/>
                                    <p style={{fontWeight: 'bold', color: 'red'}}>La compra mínima a <b>{months}</b> meses, es de <MoneyFormat>{minimumCost}</MoneyFormat></p>
                                </React.Fragment> : null
                            }
                        </FormGroup> 
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={calcWorking || working || minimumNotReached || !buyState.paymentType || !companyActive || buyState.totalGlobalTimestamps <= 0 || pendingPayments} color="primary">Pagar</Button>
                        <Button disabled={calcWorking || working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <StripeTimestampsModal
                isOpen={paymentModal.open}
                buyState={buyState}
                months={months}
                discount={discount}
                onCancel={modalClose}
                onStripeApproved={stripeApproved} />
            <ConfirmationModal isOpen={showConfModal}
                onCancel={handleConfCancel}
                onOk={handleSpeiPurchaseClick}
                title="Comprar paquete de sellos NOM151"
                processing={processingPayment}>
                    <p>
                        Estás por comprar un paquete de <b>{buyState.totalGlobalTimestamps} sellos NOM151</b> usando <b>SPEI</b>. Al seleccionar <b>'Comprar'</b> se te 
                        asignarán las sellos NOM151 y tendras 3 días hábiles para realizar el pago por <b>SPEI</b></p>
                    <p>¿Deseas continuar?</p>
            </ConfirmationModal>
            <EmailNoticeSPEI
                isOpen={noticeModal.open}
                onClose={noticeClose}
            />
        </React.Fragment>        
    )
}

export default BuyGlobalTimestampsModal