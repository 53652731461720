import React, {useState, useEffect, useContext} from 'react';
import { httpGet, httpPost } from '../shared/Config';
import Section from '../shared/Section';
import Table from '../shared/Table'
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Alert, Collapse, FormGroup, Label, Input, ModalHeader, ModalFooter, ModalBody, Modal, Form} from 'reactstrap';
import { useToggle } from '../shared/Hooks';
import ConfirmationModal from '../shared/ConfirmationModal';

function AssignTimestampsModal({isOpen, onCancel, onSaved, orgId, orgName}) {

    const [working, setWorking] = useState(false);
    const [showConfModal, setShowConfModal] = useState(false); 
    const [remainingGlobalTimestamps, setRemainingGlobalTimestamps] = useState({});

    async function fetchRemainingGlobalTimestamps() {
        const response = await httpGet(`/orgs/globalTimestamps/remaining`);
        console.log('remaining timestamps: ');
        console.log(JSON.stringify(response.data));
        setRemainingGlobalTimestamps({...response.data});
    }

    function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        setWorking(true);
        setShowConfModal(true);         
    }

    const [assignState, setAssignState] = useState({
        totalTimestampsToAssign: 0
    });

    function handleAssignStateChange(name) {
        return function(event) {
            let value;
            if(event.target.value <= remainingGlobalTimestamps.timestamps) {
                value = event.target.value;
                setAssignState({...assignState, [name]:value});
            }
        }
    }

    async function handleConf() {
        try {            
            setShowConfModal(false);
            const response = await httpPost(`/orgs/${orgId}/assignTimestamps`, {
                assignState
            })
            const {success, error} = response.data;
            if(success) {
                setAssignState({...assignState, totalTimestampsToAssign:0});
                onSaved();
            }
            setWorking(false);
        } catch(e) {
            setWorking(false);
        }
    }

    function handleConfCancel() {
        setWorking(false);
        setShowConfModal(false);
    }

    useEffect(() => {
        if(isOpen) {
            fetchRemainingGlobalTimestamps();
        }
    }, [isOpen]);

    function handleCancel() {
        onCancel();
    }

    return (
        <React.Fragment>
            <Modal isOpen={isOpen}>
                <Form onSubmit={handleSubmit}>
                    <ModalHeader>
                        Asignar sellos NOM151 a departamento
                    </ModalHeader>
                    <ModalBody>    
                        Sellos NOM151 restantes: {remainingGlobalTimestamps.timestamps}
                        <br/>
                        <br/>
                        <FormGroup tag="fieldset" >
                            <FormGroup>
                                <Label for="totalTimestampsToAssign">Cantidad de sellos NOM151 a asignar</Label> 
                                <Input type="number" id="totalTimestampsToAssign" value={assignState.totalTimestampsToAssign} onChange={handleAssignStateChange('totalTimestampsToAssign')} required/>
                            </FormGroup>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" disabled={working || assignState.totalTimestampsToAssign < 1} color="primary">Asignar sellos NOM151</Button>
                        <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <ConfirmationModal isOpen={showConfModal}
                onCancel={handleConfCancel}
                onOk={handleConf}
                title="Asignar sellos NOM151 a departamento">
                    <p>Estás por asignar <b>{assignState.totalTimestampsToAssign}</b> sellos NOM151 al departamento: <b>{orgName}</b> (id:{orgId})</p>
                    <p>¿Deseas continuar?</p>
            </ConfirmationModal>
        </React.Fragment>        
    )
}


export default AssignTimestampsModal