import React from 'react';

function UserAdded() {

    return(
        <div className="container">
            <p>Usuario agregado con exito. Puede cerrar esta pestaña.</p>
        </div>
    )
}

export default UserAdded