import React, {useState, useEffect} from 'react';
import Section from '../shared/Section';
import Table from '../shared/Table';
import {httpGet} from '../shared/Config';
import MoneyFormat from '../shared/MoneyFormat'
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import { Collapse, Button, Card } from 'reactstrap';
//import {Elements} from '@stripe/react-stripe-js';
//import {loadStripe} from '@stripe/stripe-js';

//const stripeObject = loadStripe(process.env.REACT_APP_HOST);

function PaymentsListSection({status, title, desc, hideIfNoData, typeString}) {
    const [payments, setPayments] = useState([]);
    const [noPayments, setNoPayments] = useState('');
    //const [activePayment, setActivePayment] = useState(null);

    /*const [paymentModal, setPaymentModal] = useState({
        open: false,
        activePayment: null
    });*/

    useEffect(() => {
        async function fetchPayments() {
            const response = await httpGet(`/payments/${status}`);
            setPayments(response.data);
            if(response.data.length === 0) {
                setNoPayments("No hay transacciones "+typeString+" en este momento.")
            }
        }

        fetchPayments();
    }, [status]);

    useEffect(() => {
        console.log("Hola" + payments.length)
    }, [payments]);

    /*function payItem(event, index) {
        setPaymentModal({...paymentModal, open: true, activePayment: index});
    }

    function modalClose() {
        setPaymentModal({...paymentModal, open: false, activePayment: null});
    }

    function stripeApproved() {
        setPaymentModal({...paymentModal, open: false}); // message confirmation
    }*/

    function PaymentsTable() {
        if(hideIfNoData && payments.length === 0) {
            return (
                <Section>
                    <p>{noPayments}</p>
                </Section>
            );
        }
        return (
            <Section>
                <h4>{title}</h4>
                {desc}
                <br/>
                {/*<StripeModal
                    isOpen={paymentModal.open}
                    paymentId={paymentModal.activePayment}
                    onCancel={modalClose}
                onStripeApproved={stripeApproved} />*/}
                <p>{noPayments}</p>
                <Table collection={payments} noData="Sin información">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Concepto</th>
                            <th>Compra</th>
                            <th>Monto</th>
                            <th>Referencia</th>
                            <th>Fecha</th>
                            <th>¿Pago a mensualidades?</th>
                            {
                                status === 'PENDING_SPEI' ?
                                <th>Fecha límite</th> : null
                            }
                            {/*<th>Pagar</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            payments.map((p,i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>
                                        {
                                            p.masterOrganization ? <React.Fragment>
                                                {
                                                    p.buyType ? <React.Fragment>
                                                        {
                                                            p.buyType === "DOCUMENTS_AND_TIMESTAMPS" || p.buyType === "DOCUMENTS" ? <React.Fragment>Compra de documentos</React.Fragment> : null
                                                        }
                                                        {
                                                            p.buyType === "TIMESTAMPS" ? <React.Fragment>
                                                                Compra de sellos NOM151
                                                            </React.Fragment> : null
                                                        }
                                                    </React.Fragment> : <React.Fragment>
                                                        Compra no relacionada a un departamento
                                                    </React.Fragment>
                                                }
                                            </React.Fragment>  : <React.Fragment>
                                                {p.organizationName}
                                            </React.Fragment>
                                        }
                                    </td>
                                    <td>                                                        
                                        {
                                            p.buyType === "DOCUMENTS_AND_TIMESTAMPS" || p.buyType === "DOCUMENTS" ? <React.Fragment>{p.buyAmount} documentos por {p.paymentType}</React.Fragment> : 
                                            <React.Fragment>
                                            {
                                                p.buyType === "TIMESTAMPS" ? <React.Fragment>{p.buyAmount} sellos NOM151 por {p.paymentType}</React.Fragment> : 
                                                <React.Fragment>
                                                    {p.concept} por {p.paymentType}
                                                </React.Fragment>
                                            }
                                            </React.Fragment>
                                        }
                                    </td>                                
                                    <td>
                                        <MoneyFormat>{p.amount}</MoneyFormat>
                                        {
                                            p.monthlyPay ? <React.Fragment> / <MoneyFormat>{p.monthlyAmount}</MoneyFormat> (por {p.months} meses) </React.Fragment> : null
                                        }

                                        {/*
                                            p.monthlyPay ? <React.Fragment>
                                                <MoneyFormat>{p.amount}</MoneyFormat> 
                                            </React.Fragment> : <React.Fragment>
                                                {
                                                    p.buyType === "CORPORATIVO_SINGLE_PAY" ? <MoneyFormat>{p.amount}</MoneyFormat> :
                                                    <MoneyFormat>{p.amountSinglePay}</MoneyFormat> 
                                                }
                                            </React.Fragment>
                                        */}
                                        {/*
                                            p.monthlyPay && p.buyType !== "PERSONAL_SERVICE_PAY" ? <React.Fragment> / <MoneyFormat>{p.amount/12}</MoneyFormat> (mes)</React.Fragment> : null
                                        */}                                       
                                    </td>
                                    <td>
                                        {p.reference}
                                    </td>
                                    <td>
                                        <DateFormat>
                                            {p.createdAt}
                                        </DateFormat>                                        
                                    </td>
                                    <td>
                                        {
                                            p.monthlyPay && p.buyType !== "PERSONAL_SERVICE_PAY" ? <React.Fragment>Si</React.Fragment> : <React.Fragment>No</React.Fragment>
                                        }
                                    </td>
                                    {
                                        status === 'PENDING_SPEI' ?
                                        <td>
                                            <DateFormat>{moment(p.createdAt).add(3,'days')}</DateFormat>
                                        </td> : null
                                    }
                                    {/*<td>
                                        <Button color="link" onClick={(event) =>payItem(event, p.id)} style={{ marginBottom: '1rem' }}>Pagar</Button>
                                    </td>*/}
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                
            </Section>
        )
    }

    return (
        <PaymentsTable/>
    )

}

export default PaymentsListSection;