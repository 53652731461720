import React from 'react';
import moment from 'moment';

function DateFormat({children}) {
    return (
        <React.Fragment>
            {moment(children).format('YYYY-MM-DD HH:mm')}
        </React.Fragment>
    )
}

export default DateFormat;