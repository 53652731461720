import React, { useState, useEffect } from "react";
import {httpPost, httpGet} from '../shared/Config';
import { Form, Button, Input, Label } from 'reactstrap';
import MoneyFormat from '../shared/MoneyFormat'
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

function StripeSubscriptionCheckout({buyState, onSucceeded, onWorking, onProccesEnd, orgData, planCost}) {

  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  
  const [clientSecret, setClientSecret] = useState('');
  const [subcriptionId, setSubscriptionId] = useState('');

  const [paymentIntent, setPaymentIntent] = useState('');

  const [emailReceipt, setEmailReceipt] = useState('');
  const [name, setName] = useState('');

  const [alreadyActiveSub, setAlreadyActiveSub] = useState(false);
  const [errorPreparingCheckout, setErrorPreparingCheckout] = useState(false);
  const [checkoutIntentError, setCheckoutIntentError] = useState(false);
  const [payDeclined, setPayDeclined] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setProcessing(true);
    setPayDeclined(false);
    setErrorPreparingCheckout(false);
    setAlreadyActiveSub(false);

    async function intentPayment() {
      try{

        const response = await httpPost(`/payments/subscription/intentStripePayment`, {
            buyState // we calculate everything again during the intent, to prevent price manipulation
        });
    
        if(response.data.clientSecret) {
          setClientSecret(response.data.clientSecret);
          setSubscriptionId(response.data.subscriptionId);
        } else if(response.data.status) {
          if(response.data.status === "already_active_sub") {
            setAlreadyActiveSub(true);
          } else {
            console.log('error generando carrito');
            setErrorPreparingCheckout(true);
          }
        } else {
          console.log('error generando carrito');
          setErrorPreparingCheckout(true);
        }
        
        setProcessing(false);
      } catch(e) {
        console.log('error generando carrito');
        setCheckoutIntentError(true);
      }
    }

    intentPayment();
  }, [buyState]);

  const cardStyle = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#32325d"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
  };

  async function payIntent(event){
    event.preventDefault();
    setProcessing(true);
    onWorking(); // disabled close button
    if (!stripe || !elements) {
      return;
    }

    const payload = await stripe.confirmCardPayment(clientSecret, {
      receipt_email: emailReceipt,
      payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: name
          },
      }
    });

    if (payload.error) {
        if(payload.error.code === "card_declined") {
            setPayDeclined(true);
        }

        console.log('[error]', payload.error);
    } else {
      if(payload.paymentIntent.status === "succeeded"){
          setSucceeded(true);
          setPaymentIntent(payload.paymentIntent);

          /*if(buyState.subType === "PERSONAL") {
              const responseUpdate = await httpPost(`/payments/subscriptionUpdate`, {
                clientSecret,
                buyState
              });

            if(responseUpdate) { // we got confirmation that the payment is set to pending
              onSucceeded(buyState.newOrgId);
            }
          } else*/ 
          //if(buyState.subType === "CORPORATIVO") {
          const responseUpdate = await httpPost(`/payments/updateAccountStanding`, {
            clientSecret,
            buyState,
            orgData
          });

          if(responseUpdate && responseUpdate.data.status === "success") { // we got confirmation that the payment is set to pending
            onSucceeded(responseUpdate.data.newOrgId);
          }
          //}
      }
      console.log('[payload]', payload);
    }
    setProcessing(false);
    onProccesEnd(); // enables close button
  }

  async function onCardChange(event) {

  }

  function emailReceiptChange(event) {
      setEmailReceipt(event.target.value)
  }
  
  function nameChange(event) {
    setName(event.target.value)
}
  return (
      <React.Fragment>
          <Form onSubmit={payIntent}>
                <br/>
                {/*
                  buyState.subType === "PERSONAL" ? <React.Fragment>
                    Requiere activar este departmento para poder hacer uso de el.
                    <br/>
                    Pago de suscripción para departamento <b>{buyState.subType}</b> 
                    <br/>
                    Este pago es mensual, se volvera a hacer un cargo todos los meses, puede cancelar en cualquier momento
                    <br/>
                    Cantidad que pagara mensualmente (MXN): <b><MoneyFormat>99</MoneyFormat> (incluye IVA)</b>
                    <br/>
                  </React.Fragment> : null
                */}                
                {
                  buyState.subType === "CORPORATIVO" ? <React.Fragment>
                    {/*Pago único para convertir cuenta a <b>{buyState.subType}</b> 
                    <br/>
                    Este es un pago único. Tras convertir su cuenta a CORPORATIVO recibirá <b>100 documentos, 100 estampillas</b> y podrá crear hasta 5 departamentos de manera gratuita.
                    <br/>
                    Cantidad de pago único (MXN): <b><MoneyFormat>{planCost}</MoneyFormat> (incluye IVA)</b>
                    <br/>*/}
                    No existe un costo por convertir su cuenta a ON DEMAND. Sin embargo, requiere comprar una cantidad mínima de documentos <b>(la cantidad de documentos mínima es determinada por su método de pago)</b>.
                  </React.Fragment> : null
                }
                <br/>
                <Input type="text" id="name" value={name} onChange={nameChange} placeholder="Nombre en la tarjeta" required/>
                <br/>
                <Input type="email" id="receiptEmail" value={emailReceipt} onChange={emailReceiptChange} placeholder="Correo al que se enviara el recibo de compra (opcional)" />
                <br/>
                <CardElement id="card" options={cardStyle} onChange={(e) => onCardChange(e)} />
                <div>
                </div>
                <div>
                    <Button style={{width: '100%', marginTop: '20px'}} disabled={processing || checkoutIntentError ||  succeeded} type="submit"  color="primary">Pagar</Button> 
                </div>
                {
                    succeeded ? <React.Fragment>
                    <br/>
                      El pago ha sido aceptado. Su departamento se activara cuando se procese el pago. Puede cerrar la ventana.
                    </React.Fragment> : null 
                }
                {
                    payDeclined ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>El pago fue declinado. Revise los fondos de su tarjeta ó intente más tarde.</span>
                    </React.Fragment> :
                    null
                }
                {
                    errorPreparingCheckout || checkoutIntentError ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>No se pudo generar su carrito. Intente más tarde.</span>
                    </React.Fragment> : null
                }
                {
                    alreadyActiveSub ? <React.Fragment>
                    <br/>
                    <span style={{color: 'red', fontWeight: 'bold'}}>Este departamento ya tiene una suscripción activa. Cierre esta ventana y actualice.</span>
                    </React.Fragment> : null
                }
                {/*disabled={!formIncomplete}*/}
          </Form>
          <br/>
      </React.Fragment>
  );
}
export default StripeSubscriptionCheckout;