import React from 'react';
import Login from './Login'
import Register from './Register';
import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom';

function isAuthenticated() {
    return !localStorage.getItem('jwt')
}    

function LoginContainer({match, history}) {

    return (
        <div>
        {
            isAuthenticated() ? <React.Fragment>
                <div id="LogInContainer">
                    <div className="flex-left">
                    <Route path="/login" component={Login}/>
                    {/*<Route path="/register" component={Register}/>*/}
                    </div>
                    <div className="flex-right">
                    <div className="side-text">
                        <div className="side-text-big">
                            Firmas electrónicas fáciles
                        </div>
                        <br/>
                        <div className="side-text-big">
                            de usar con la más alta
                        </div>
                        <br/>
                        <div className="side-text-big">
                            seguridad
                        </div>
                        <br/>
                        <div className="side-text-small">
                            Trabaje más rápido utilizando firmas electrónicas legalmente válidas.
                        </div>
                    </div>
                    </div>
                </div>
            </React.Fragment> : <React.Fragment>
                <Redirect to={{
                    pathname:"/console/orgs"
                    }}/>     
                </React.Fragment>
        }
        </div>
    )
}

export default LoginContainer;