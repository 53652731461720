import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {httpGet, backend} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap'

const defaultData = {
    name: '',
    rfc: '',
    contactName: '',
    contactEmail: '',
    contactPhone: '',
    postalCode: '',
    cfdi: '',
    regimenFiscal: ''
}

function InvoiceDataModal({isOpen, initialState, onCancel, onSaved, regimenFiscalesSelect}) {
    const [iData, setIData] = useState(defaultData)
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setIData(initialState || defaultData);
    }, [isOpen, initialState])
    
    function handleCancel() {
        onCancel();
    }
    function handleTextChange(attr) {
        return function(event) {
            setIData({...iData, [attr]:event.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/invoiceData'), iData);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }

    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Información de facturación
                </ModalHeader>
                <ModalBody>                
                    <FormGroup>
                        <Label for="name">Razón social</Label>
                        <Input type="text" id="name" value={iData.name} onChange={handleTextChange('name')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="rfc">RFC</Label>
                        <Input type="text" id="rfc" value={iData.rfc} onChange={handleTextChange('rfc')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="nombreFacturacion">Nombre</Label>
                        <Input type="text" id="nombreFacturacion" value={iData.contactName} onChange={handleTextChange('contactName')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Correo electrónico</Label>
                        <Input type="email" id="emailFacturacion" value={iData.contactEmail} onChange={handleTextChange('contactEmail')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telefonoFacturacion">Telefono</Label>
                        <Input type="number" id="telefonoFacturacion" value={iData.contactPhone} onChange={handleTextChange('contactPhone')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="postalCode">Código postal</Label>
                        <Input type="text" id="postalCode" value={iData.postalCode} onChange={handleTextChange('postalCode')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="CFDI">Uso del CFDI</Label>
                        <Input id="cfdi" type="select" value={iData.cfdi} onChange={handleTextChange('cfdi')} required>
                            <option value=""></option>
                            <option value="Adquisición de mercancías">Adquisición de mercancías</option>
                            <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                            <option value="Gastos en general">Gastos en general</option>
                            <option value="Construcciones">Construcciones</option>
                            <option value="Mobiliario y equipo de oficina por inversiones">Mobiliario y equipo de oficina por inversiones</option>
                            <option value="Equipo de transporte">Equipo de transporte</option>
                            <option value="Equipo de cómputo y accesorios">Equipo de cómputo y accesorios</option>
                            <option value="Dados, troqueles, moldes, matrices y herramental">Dados, troqueles, moldes, matrices y herramental</option>
                            <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                            <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                            <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                            <option value="Honorarios médicos, dentales y gastos hospitalarios.">Honorarios médicos, dentales y gastos hospitalarios</option>
                            <option value="Gastos médicos por incapacidad o discapacidad">Gastos médicos por incapacidad o discapacidad</option>
                            <option value="Gastos funerales">Gastos funerales</option>
                            <option value="Donativos">Donativos</option>
                            <option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                            <option value="Aportaciones voluntarias al SAR">Aportaciones voluntarias al SAR</option>
                            <option value="Primas por seguros de gastos médicos.">Primas por seguros de gastos médicos</option>
                            <option value="Gastos de transportación escolar obligatoria.">Gastos de transportación escolar obligatoria</option>
                            <option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                            <option value="Pagos por servicios educativos (colegiaturas)">Pagos por servicios educativos (colegiaturas)</option>
                            <option value="Por definir">Por definir</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="regimenFiscal">Régimen fiscal</Label>
                        <Input id="regimenFiscal" type="select" value={iData.regimenFiscal.id} onChange={handleTextChange('regimenFiscal')} required>
                            <option value=""></option>
                            {regimenFiscalesSelect}
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" disabled={working} color="primary">Guardar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default InvoiceDataModal;