import React from 'react';
import {Route, Redirect} from 'react-router-dom';

function isAuthenticated() {
    return !!localStorage.getItem('jwt')
}    

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => 
                isAuthenticated() ? (
                    <Component {...props}/>
                ) : (
                    <Redirect to={{
                        pathname: "/login"
                    }}/>
                )
            }
        />
    )
}

export default PrivateRoute;