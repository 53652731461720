import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LoginContainer from './LoginContainer';
import AdminContainer from './AdminContainer';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" render={ () =>
          <Redirect to={{ pathname:"/login"}}/>
        }/>
        <Route path={["/verify/:token", "/console"]} component={AdminContainer} />
        <Route path={["/login", "/register"]} component={LoginContainer} />
      </Switch>
    </Router>
  );
}

export default App;