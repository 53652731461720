
import React from 'react';
import {httpGet} from '../shared/Config'


const types = {
    UPDATE_PAYMENT_INFO: 'UPDATE_PAYMENT_INFO'
}

const ConsoleDispatch = React.createContext(null)

function dispatchMiddleware(dispatch) {
    return async function(action) {
        switch(action.type) {
            case types.UPDATE_PAYMENT_INFO: {                
                const response = await httpGet('/payments/checkPending')
                return dispatch({...action, data: response.data})
            }
            default: {
                return dispatch(action)
            }
        }
    }
}

function consoleReducer(state, action) {
    switch(action.type) {
        case types.UPDATE_PAYMENT_INFO: {            
            return {
                ...state, pendingPayments: action.data.pending          
            }
        }
        default: {
            return state;
        }            
    }
}

export {
    types, consoleReducer, dispatchMiddleware, ConsoleDispatch
}