import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';

const defaultData = {
    certData: '',
    email: '',
    type: ''
}

const DataTypes = {
    RFC: 'RFC',
    EMAIL: 'Correo',
    PHONE: 'Teléfono móvil'
}

function UserTokenModal({isOpen, onCancel, onSaved, orgId, planData, firstUser}) {

    const [user, setUser] = useState(defaultData)
    const [working, setWorking] = useState(false);   

    const [serverConf, setServerConf] = useState({});
    
    function handleCancel() {
        setUser(defaultData);
        onCancel();
    }


    useEffect(() => {
        async function fetchServerConf() {
            try {
                const response = await httpGet('/getServerConf');
                if(response?.data.jsonData) {
                    let parseConf = JSON.parse(response.data.jsonData);
                    setServerConf(parseConf);
                    let value = user.type;
                }
            } catch(e) {
                console.error(e);
            }
        }
        fetchServerConf();
    }, []);

    function handleTextChange(attr) {
        return function(event) {
            setUser({...user, [attr]:event.target.value})
        }
    }

    function handleSelectChange(attr) {
        return function(event) {       
            setUser({...user, type:event.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();    
        setWorking(true);
        try {
            const postData = user;
            if(user.type === 'EMAIL') {
                // si el tipo de autoridad es correo
                // asignamos el correo directamente como el dato
                // que buscamos en el certificado
                postData.certData = user.email;
            }
            const response = await httpPost(`/orgs/${orgId}/users/add`, postData);
            setWorking(false);            
            if(response?.data.success) {
                setUser(defaultData);                                
                onSaved({...response.data.userToken, ...JSON.parse(response.data.userToken.json)});
            }            
        } catch(e) {
            setWorking(false);
        }

    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Usuario
                </ModalHeader>
                <ModalBody>     
                    {
                        planData.plan.limitsUsers && !firstUser ? 
                        <p style={{fontWeight:'bold'}}>Se te facturará cada usuario adicional de acuerdo a la tarifa descrita en la página de precios</p> : null
                    }
                    <p>
                        Se enviará una invitación a firmar al correo indicado. Cuando el usuario firme el documento, quedará
                        registrado en tu organización y podrá iniciar documentos e invitar a otros a firmar
                    </p>
                    <FormGroup>
                        <Label for="certificateType">Tipo de certificado</Label>
                        <Input type="select" id="certificateType" value={user.type}
                            onChange={handleSelectChange('authority')}
                            required>
                                <option value=""></option>
                                { serverConf?.userMode === "core" ? <option value="EMAIL">Correo Electrónico</option> : null }
                                <option value="SAT">SAT</option>
                                { serverConf?.userMode === "chihuahua" ? <option value="Chihuahua">Chihuahua</option> : null }
                        </Input>
                    </FormGroup>
                    {
                        user.type === 'SAT' || user.type === 'Chihuahua' ?
                        <FormGroup>
                            <Label for="certData">RFC</Label>
                            <Input type="text" id="certData" value={user.certData} onChange={handleTextChange('certData')} required/>
                        </FormGroup> : null
                    }
                    {
                        user.type ? 
                        <FormGroup>
                            <Label for="email">Correo</Label>
                            <Input type="email" id="email" value={user.email} onChange={handleTextChange('email')} required/>
                        </FormGroup> : null
                    }                         
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" disabled={working || !user.type} color="primary">Guardar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default UserTokenModal;