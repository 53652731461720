import React, {useReducer, useEffect, useState} from 'react';
import {Route, Link, LinkContainer, Switch} from 'react-router-dom'
import ConsoleMenu from './home/ConsoleMenu';
import InvoiceDataList from './invoiceData/InvoiceDataList';
import SupportDataList from './supportData/SupportDataList';
import Organizations from './organizations/Organizations';
import Organization from './organizations/Organization';
import NewOrganization from './organizations/NewOrganization';
import Payments from './payments/Payments';
import {Navbar, NavbarBrand, Nav, NavItem} from 'reactstrap';
import PendingPaymentsAlert from './shared/PendingPaymentsAlert';
import {consoleReducer, types, dispatchMiddleware, ConsoleDispatch} from './reducers/reducers';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import IconButton from './shared/IconButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import EmailRegistration from './info/EmailRegistration';
import UserAdded from './info/UserAdded';

import {httpGet} from './shared/Config';

function FirmamexConsole({match}) {
    
    const [consolec, dispatch] = useReducer(consoleReducer, {})    
    const dispatcher = dispatchMiddleware(dispatch);
    const [serverConf, setServerConf] = useState({});
    const [checkDone, setCheckDone] = useState(false);

    const [clientType, setClientType] = useState('DEMO');

    useEffect(() => {
        dispatcher({type: types.UPDATE_PAYMENT_INFO});
        fetchAccountStatus();
    }, [])    


    function handleSignOutClick() {
        localStorage.removeItem('jwt');
        window.location.href = 'https://firmamex.com/pdf/default/';
    }

    function handleAppClick() {
        window.open('https://firmamex.com/pdf/default/', '_blank');
    }

    async function fetchAccountStatus() {
        const response = await httpGet(`/orgs/accountStanding/clientType`);

        if(response.data) {
            if(response.data.clientType) {
                let cType = response.data.clientType;
                setClientType(cType);
                fetchServerConf();
            }
        }
    }

    async function fetchServerConf() {
        try {
            const response = await httpGet('/getServerConf');
            if(response?.data.jsonData) {
                let parseConf = JSON.parse(response.data.jsonData);
                setServerConf(parseConf);
                setCheckDone(true);
            }
        } catch(e) {
            console.error(e);
        }
    }

    return (
        <ConsoleDispatch.Provider value={dispatcher}>
            <Navbar className="NavBar" dark expand="md">
                <NavbarBrand href="/console/orgs">Portal de Administración</NavbarBrand>
                <Nav className="ml-auto">
                    <Link to="#" onClick={handleAppClick}>
                        <NavItem style={{color:'white', marginRight:'20px'}} href="">
                            <FontAwesomeIcon style={{transform:'translateX(450%)'}}size="lg" icon={faLevelUpAlt}/>
                            <br/><span style={{fontWeight:'bold'}}>Ir a la aplicación</span>
                        </NavItem>
                    </Link>
                    <Link to="/console/orgs">
                        <NavItem style={{color:'white', marginRight:'20px'}} href="">
                            <FontAwesomeIcon style={{transform:'translateX(90%)'}}size="lg" icon={faUsers}/>
                            <br/><span style={{fontWeight:'bold'}}>Mi cuenta</span>
                        </NavItem>
                    </Link>
                    {
                        checkDone && clientType !== "DEMO" && serverConf?.userMode !== "chihuahua" ? <React.Fragment>
                            <Link to="/console/payments">
                                <NavItem style={{color:'white', marginRight:'20px'}} href="">
                                    <FontAwesomeIcon style={{transform:'translateX(350%)'}} size="lg" icon={faBook}/>
                                    <br/><span style={{fontWeight:'bold'}}>Información de pagos</span>
                                </NavItem>
                            </Link>
                            <Link to="/console/invoiceData">
                                <NavItem style={{color:'white', marginRight:'20px'}} href="">
                                    <FontAwesomeIcon style={{transform:'translateX(450%)'}} size="lg" icon={faFileInvoiceDollar}/>
                                    <br/><span style={{fontWeight:'bold'}}>Facturación y soporte</span>
                                </NavItem>
                            </Link>
                        </React.Fragment> : null
                    }
                    {/*<Link to="/console/supportData">
                        <NavItem style={{color:'white'}} href="">
                            <FontAwesomeIcon style={{transform:'translateX(120%)'}} size="lg" icon={faAddressBook}/>
                            <br/><span style={{fontWeight:'bold'}}>Soporte</span>
                        </NavItem>
                    </Link>*/}
                </Nav>
                <Nav className="ml-auto" navbar>
                    <NavItem onClick={handleSignOutClick}>                        
                        <span className="LogoutText">Cerrar sesión </span>
                        <IconButton icon={faSignOutAlt} 
                            style={{color:'white', fontSize:'20px'}}/>                
                    </NavItem>
                </Nav>
            </Navbar>            
            <br/>
            <div className="container">        
                <PendingPaymentsAlert count={consolec.pendingPayments}/>
                <br/>
                <Route exact path={`${match.path}/`} component={ConsoleMenu}/>
                <Route path={`${match.path}/invoiceData`} component={InvoiceDataList}/>
                <Route path={`${match.path}/supportData`} component={SupportDataList}/>
                <Route path={`${match.path}/payments`} component={Payments}/>
                <Switch>
                    <Route exact path={`${match.path}/orgs/new`} component={NewOrganization}/>
                    <Route exact path={`${match.path}/orgs`} component={Organizations}/>
                    <Route exact path={`${match.path}/emailRegistered`} component={EmailRegistration}/>
                    <Route exact path={`${match.path}/userAdd`} component={UserAdded}/>
                    <Route path={`${match.path}/orgs/:id`} component={Organization}/>
                </Switch>                
            </div>
        </ConsoleDispatch.Provider>
    )
}

export default FirmamexConsole;
