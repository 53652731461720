import React from 'react';
import numeral from 'numeral';

function MoneyFormat({children}) {
    return (
        <React.Fragment>
            {numeral(children).format('$0,0.00')}
        </React.Fragment>
    )
}

export default MoneyFormat;