import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from 'reactstrap';

function IconButton({icon, ...rest}) {    
    return (
        <FontAwesomeIcon icon={icon} className="IconButton" {...rest}/>
    )
}

export default IconButton;