import React from 'react';
import Section from './Section'
import {Table as BTable} from 'reactstrap'

function TableSection({title, children, collection, noData}) {
    return (
        <React.Fragment>
            {
                title ? <h1>{title}</h1> : null
            }
            {
                collection && collection.length > 0 ? 
                    <BTable>{children}</BTable> : 
                    <div className="NoData">
                        {noData}
                    </div>                
            }
        </React.Fragment>
    )
}

export default TableSection