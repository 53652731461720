import React, {useState, useEffect} from 'react'
import axios from 'axios';
import {backend} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap'

const defaultData = {
    supportName: '',
    supportEmail: '',
    supportPhone: ''      
}

function SupportDataModal({isOpen, initialState, onCancel, onSaved}) {

    const [iData, setIData] = useState(defaultData)
    const [working, setWorking] = useState(false);

    useEffect(() => {
        setIData(initialState || defaultData);
    }, [isOpen, initialState])
    
    function handleCancel() {
        onCancel();
    }

    function handleTextChange(attr) {
        return function(event) {
            setIData({...iData, [attr]:event.target.value})
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        setWorking(true);
        try {
            const response = await axios.post(backend('/supportData'), iData);
            setWorking(false);
            onSaved(response.data);
        } catch(e) {
            setWorking(false);
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <Form onSubmit={handleSubmit}>
                <ModalHeader>
                    Información de contacto de soporte
                </ModalHeader>
                <ModalBody>                
                    <FormGroup>
                        <Label for="name">Nombre de contacto de soporte</Label>
                        <Input type="text" id="name" value={iData.supportName} onChange={handleTextChange('supportName')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email de contacto de soporte</Label>
                        <Input type="email" id="email" value={iData.supportEmail} onChange={handleTextChange('supportEmail')} required/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="telefono">Telefono de contacto de soporte</Label>
                        <Input type="number" id="telefono" value={iData.supportPhone} onChange={handleTextChange('supportPhone')} required/>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit" disabled={working} color="primary">Guardar</Button>
                    <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}

export default SupportDataModal;