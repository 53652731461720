import React, {useState, useEffect} from 'react';

export function useToggle(initialState) {
    const [val, setVal] = useState(initialState);

    function toggle() {
        setVal(!val);
    }

    return [val, toggle]
}

export function useIsMobile() {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {

        function handleResize() {
            setIsMobile(window.innerWidth < 500);
        }
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }   
    })

    return isMobile
}