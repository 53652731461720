import React, {useState, useEffect} from 'react'
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

function ConfirmReactivateModal({isOpen, onCancel, onOk, title, children, reactivateComplete, reactivateWorking}) {
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>     
                {children}   
            </ModalBody>
            <ModalFooter>
                <Button disabled={reactivateComplete || reactivateWorking} color="primary" onClick={onOk}>Reactivar suscripción</Button>
                <Button disabled={reactivateWorking} color="secondary" onClick={onCancel}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmReactivateModal;