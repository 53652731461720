import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import InvoiceDataModal from '../invoiceData/InvoiceDataModal'

function SelectGlobalInvoiceData({disabled, regimenFiscalesSelect}) {
    
    const [invoiceDataList, setInvoiceDataList] = useState([]);
    const [invoiceModal, setInvoiceModal] = useState({
        open: false,
        initialState: null
    });
    const [valueSelected, setValueSelected] = useState();
    const [invoiceInfo, setInvoiceInfo] = useState({
        id: '',
        rfc: ''
    })

    const [activeInvoiceInfo, setActiveInvoiceInfo] = useState({
        id: ''
    })
    const [working, setWorking] = useState(false);

    const [invoiceChecked, setInvoiceChecked] = useState(false);
    const [noInvoice, setNoInvoice] = useState(true);
    const [autoSet, setAutoSet] = useState(false);
    const [noInvoiceList, setNoInvoiceList] = useState(true);

    const [error, setError] = useState('');

    useEffect(() => {        
        fetchInvoiceDataList(true);
    }, [])

    async function fetchInvoiceDataList(globalFetch) {
        setNoInvoice(true);        
        setWorking(true);
        try {
            const response = await httpGet(`/invoiceData/select`);
            if(response.data) {
                setInvoiceDataList(response.data);
                
                if(response.data.length > 0){
                    setNoInvoiceList(false);
                } else {
                    setAutoSet(true);
                }

                if(globalFetch){
                    const currentValue = await httpGet(`/invoiceData/getGlobalInvoice`);
                    if(currentValue.data && currentValue.data.id != null) {
                        setValueSelected(currentValue.data.id);
                        setInvoiceInfo(currentValue.data);
                        setActiveInvoiceInfo(currentValue.data);
                        setNoInvoice(false);
                    } else {
                        setNoInvoice(true);

                        if(response.data.length > 0){
                            const responseDef = await httpGet(`/invoiceData/${response.data[0].id}`);
                            setInvoiceInfo({...responseDef.data, working: false});
                            setValueSelected(response.data[0].id);
                        }
                    }
                }
                setInvoiceChecked(true);
            }
        } catch(e) {
            console.error(e);
            setInvoiceDataList([]);
            setWorking(false);
        }
        setWorking(false);
    }

    function handleSelectChange(event) {
        setValueSelected(event.target.value);
        //onChange(event.target.value);
        async function fetchInvoiceData() {
            const response = await httpGet(`/invoiceData/${event.target.value}`);
            setInvoiceInfo({...response.data, working: false});
        }
        fetchInvoiceData();
    }

    function handleInvoiceDataSaved(dataSaved) {
        setError('');
        setInvoiceModal({...invoiceModal, open:false})
        fetchInvoiceDataList(false);
        setValueSelected(dataSaved.id);
        setInvoiceInfo(dataSaved);
        if(dataSaved.id === activeInvoiceInfo.id) {
            setActiveInvoiceInfo(dataSaved);
        }
        if(autoSet) {
            async function updateInvoice(){
                await httpPost(`/invoiceData/updateInvoiceData`, {
                    invoiceDataId: dataSaved.id
                });
                setNoInvoice(false);
                setActiveInvoiceInfo(dataSaved);
            }
            updateInvoice();
            setAutoSet(false);
        }
        setNoInvoice(false);
    }

    function handleEdit(iData) {
        return function() {
            if(!iData.regimenFiscal) {
                iData.regimenFiscal = {id: 0};
            }
            setInvoiceModal({initialState: iData, open: true})            
        }
    }

    function handleUpdateInvoice() {
        if(invoiceInfo.id !== activeInvoiceInfo.id) {
            async function updateInvoice(){
                await httpPost(`/invoiceData/updateInvoiceData`, {
                    invoiceDataId: valueSelected
                });
                setNoInvoice(false);
                setActiveInvoiceInfo(invoiceInfo);
            };
            
            updateInvoice();
        }
    }

    function handleAdd() {
        setInvoiceModal({initialState: null, open: true})
    }

    return (
        <React.Fragment>
            <br/>
            <h4>Contacto de facturación</h4>
            <br/>
            {
                invoiceChecked ? <React.Fragment>
                    {
                        !noInvoiceList ? <React.Fragment>
                            {
                                noInvoice ? <React.Fragment>
                                    <span style={{color: 'red', fontWeight:'bold'}}>No cuentas con un contacto de facturación activo, favor de seleccionar uno.</span>
                                </React.Fragment> : <React.Fragment>
                                    <b>Contacto activo</b>
                                    <br/>
                                    <p>
                                        RFC: {activeInvoiceInfo.rfc} <br/>
                                        Email: {activeInvoiceInfo.email} <br/>
                                        Código postal: {activeInvoiceInfo.postalCode} <br/>
                                        { activeInvoiceInfo.cfdi ? <React.Fragment>CFDI: {activeInvoiceInfo.cfdi}</React.Fragment> : null}<br/>
                                        { activeInvoiceInfo.regimenFiscal ? <React.Fragment>Régimen fiscal: {activeInvoiceInfo.regimenFiscal.data}</React.Fragment> : null}
                                    </p>       
                                </React.Fragment>
                            }
                            <br/>
                            <hr/>
                            <br/>
                            <h4>Cambiar contacto de facturación</h4>
                            <br/>
                            <FormGroup>
                                <div className="row justify-content-end">
                                    <Button disabled={working} style={{marginRight:'10px'}} color="primary" onClick={handleEdit(invoiceInfo)}>Editar este contacto</Button>
                                </div>
                                <br/>
                                <Input type="select" id="invoiceData" value={valueSelected}
                                    onChange={handleSelectChange}
                                    disabled={working}
                                    required>
                                    {
                                        invoiceDataList.map(i => (
                                            <option key={i.id} value={i.id}>{i.email} - {i.rfc}</option>
                                        ))
                                    }
                                </Input>
                            </FormGroup>
                            {
                                invoiceInfo.id === activeInvoiceInfo.id ? <React.Fragment>
                                    <b>Este es el contacto activo</b>
                                </React.Fragment> : null
                            }
                            <p>
                                RFC: {invoiceInfo.rfc} <br/>
                                Email: {invoiceInfo.email} <br/>
                                Código postal: {invoiceInfo.postalCode}<br/>
                                { invoiceInfo.cfdi ? <React.Fragment>CFDI: {invoiceInfo.cfdi}</React.Fragment> : null} <br/>
                                { invoiceInfo.regimenFiscal ? <React.Fragment>Régimen fiscal: {invoiceInfo.regimenFiscal.data}</React.Fragment> : null}
                            </p>
                            <div className="row justify-content-end">
                                <Button disabled={working} color="primary" onClick={handleUpdateInvoice}>Actualizar mi contacto de facturación</Button>
                            </div>           
                            <br/>                        

                        </React.Fragment> : <React.Fragment>
                            <br/>
                            <span style={{color: 'red', fontWeight:'bold'}}>No cuentas con ningun contacto de facturación, favor de agregar uno.</span>
                        </React.Fragment>
                    }
                </React.Fragment> : null
            }                            
            <hr/>
            <div className="row justify-content-center">
                <Button disabled={working} color="primary" onClick={handleAdd}>Agregar un nuevo contacto de facturación</Button>
            </div>        
            <br/>  
            <InvoiceDataModal
                isOpen={invoiceModal.open}
                initialState={invoiceModal.initialState}
                onCancel={() => setInvoiceModal({initialState: null, open: false})}
                onSaved={handleInvoiceDataSaved}
                regimenFiscalesSelect={regimenFiscalesSelect}/>
        </React.Fragment>
    )

}

export default SelectGlobalInvoiceData