import React, {useState, useEffect} from 'react';
import Section from '../shared/Section';
import Table from '../shared/Table';
import PaymentsListSection from './PaymentsListSection';
import SpeiInfoModal from '../shared/SpeiInfoModal';
import {Button} from 'reactstrap'
import PackagesBoughtInfoListSection from './PackagesBoughtInfoListSection';

function Payments() {

    const [showSpeiInfo, setShowSpeiInfo] = useState(false)    

    return (
        <div>
            <h3>Información de pagos</h3>
            <br/>
            <PaymentsListSection
                hideIfNoData={true}
                title="Pendientes a transferir (SPEI)"
                desc={
                    <p>
                        Por favor realiza la transferencia antes de la fecha límite para poder continuar utilizando 
                        el servicio. <Button color="link" onClick={() => setShowSpeiInfo(true)}>Más información</Button>
                    </p>
                }
                status="PENDING_SPEI"
                typeString="'Pendientes de SPEI'" />
            <PaymentsListSection
                hideIfNoData={true}
                title="Completados"
                status="COMPLETED"
                typeString="'Completadas'"/>
            <SpeiInfoModal
                isOpen={showSpeiInfo}
                onClose={() => setShowSpeiInfo(false)}></SpeiInfoModal>
            <PackagesBoughtInfoListSection 
                hideIfNoData={true}
                title="Paquetes comprados"
                desc={
                    <div>
                        <p>                            
                            En esta sección podrá encontrar los paquetes que ha comprado al igual que la fecha de entrega de paquetes y su fecha de expiración.
                            Puede filtrar esta información por paquetes expirados y activos. Los paquetes pueden cambiar entre expirados o no expirados,
                            dependiendo de acciones del usuario como el borrado de documentos.
                        </p>
                        <p>Si su paquete aparece como expirado pero no ha llegado su fecha de expiración, es porque ya ha consumido todos los documentos.</p>
                    </div>
                }
                status="Todos"
                typeString="todos" />
        </div>
    )
}

export default Payments;