import React, {useState, useEffect} from 'react'
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faUniversity } from '@fortawesome/free-solid-svg-icons'

function SpeiInfoModal({isOpen, onClose}) {
    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Información para transferencia
            </ModalHeader>
            <ModalBody>     
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col" style={{textAlign:'center'}}>
                            <div><FontAwesomeIcon icon={faUniversity} style={{fontSize:'50px'}}></FontAwesomeIcon></div>
                            <br/>
                            <p>
                                El pago de cualquier compra se realizará por medio de SPEI. 
                                <span className="Bold"> Es importante que al momento de hacer la transferencia indiques la referencia que aparece en el detalle del pago</span>
                            </p>
                            <p>
                                Beneficiario<br/>
                                FIRMAMEX TECHNOLOGIES
                            </p>
                            <p>
                                Banco<br/>
                                HSBC
                            </p>
                            <p>
                                Cuenta<br/>
                                4062708029
                            </p>
                            <p>
                                CLABE<br/>   
                                021180040627080294 
                            </p>
                        </div>
                    </div>                    
                </div>                
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onClose}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default SpeiInfoModal;