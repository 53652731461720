import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import FirmamexConsole from './Console'
import LoginToken from './LoginToken'
import PrivateRoute from './shared/PrivateRoute'

function AdminContainer({match, history}) {

    return (
        <div id="AdminContainer">
            <Route path="/verify/:token" component={LoginToken}/>
            <PrivateRoute path="/console" component={FirmamexConsole}/>
        </div>
    )
}

export default AdminContainer;