import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';


function UserTokenModal({isOpen, onCancel, onDeleted, user, orgId}) {

    const [working, setWorking] = useState(false);   
    
    function handleCancel() {
        onCancel();
    }

    async function handleOk() {
        setWorking(true);
        try {
            const response = await httpPost(`/orgs/${orgId}/users/remove`, {
                userId: user.id
            });            
            setWorking(false);
            if(response.data && response.data.success) {
                onDeleted(user);
            }            
        } catch(e) {
            console.error(e);
            setWorking(false);
        }
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Eliminar usuario
            </ModalHeader>
            <ModalBody>     
                <p>
                    Está por eliminar al usuario {user.certData}.
                </p>                              
                <p>
                    Al eliminarlo, este usuario podrá seguir firmando pero ya no podrá subir documentos o 
                    invitar a firmar
                </p>
            </ModalBody>
            <ModalFooter>
                <Button disabled={working} color="primary" onClick={handleOk}>Aceptar</Button>
                <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default UserTokenModal;