import React, {useState, useEffect} from 'react'
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

function ConfirmationModal({isOpen, onCancel, onOk, title, children, processing}) {

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                {title}
            </ModalHeader>
            <ModalBody>     
                {children}                              
            </ModalBody>
            <ModalFooter>
                <Button disable={processing} color="primary" onClick={onOk}>Comprar</Button>
                <Button disable={processing} color="secondary" onClick={onCancel}>Cancelar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationModal;