import React, {useState} from 'react';
import {httpPost} from './shared/Config';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';

function Login() {

    const [working, setWorking] = useState(false);
    const [email1, setEmail1] = useState('');
    const [email2, setEmail2] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    function handleEmail1Change(event) {
        setEmail1(event.target.value);
    }

    function handleEmail2Change(event) {
        setEmail2(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        if(email1 !== email2) {
            setError('Los dos correos deben ser iguales');
            return;
        }
        setError('')
        setWorking(true);
        try {
            await httpPost(`/login`, {email: email1});
            setSuccess(true);
        } catch(e) {
            console.error(e);            
            setWorking(false);
        }
    }
    
    function handleRegister() {
        window.location.href = '/register';
    }

    return (
        <React.Fragment>
            {
                success ? 
                <div className="col Login" style={{maxWidth:"500px", margin:'auto', marginTop:'50px', height: '100vh'}}>
                    <br/>
                    <br/>
                    <div className="row justify-content-center">
                        <img src={process.env.PUBLIC_URL + '/logoLogin.png'} className="LoginLogo" alt="logIn"/>                     
                    </div>
                    <br/>          
                    <p>
                        En caso de existir en nuestro sistema, se habrá enviado un email al correo <b>{email1}</b>.
                        Por favor sigue las instrucciones en el correo para iniciar sesión.
                    </p>
                </div> :
                <div className="col Login" style={{maxWidth:"600px", margin:'auto', height: '100vh'}}>
                    <br/>
                    <br/>
                    <div className="row justify-content-center">
                        <img src={process.env.PUBLIC_URL + '/logoLogin.png'} className="LoginLogo" alt="logIn"/>                     
                    </div>         
                    <br/>       
                    <div className="row justify-content-center">
                        <h3>Portal de Administración</h3>
                    </div>           
                    <br/>
                    {/*<p>Bienvenido</p>
                    <p style={{textAlign:'left'}}>
                        Si aun no estas registrado, puedes dar de alta a tu empresa dando clic en el siguiente botón.
                        <br/>
                        <br/>
                        <Button style={{width:'100%'}} disabled={working} onClick={handleRegister} color="primary">Registrarse</Button>
                        <br/>
                        <br/>
                        En este portal puedes administrar tu cuenta de Firmamex, 
                        seleccionar un plan, agregar usuarios y comprar documentos                       
                    </p>   */}
                    <Form onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label for="email1">Correo</Label>
                            <Input type="email" id="email2" required
                                value={email1} onChange={handleEmail1Change}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="email2">Confirmación de correo</Label>
                            <Input type="email" id="email2" required
                                value={email2} onChange={handleEmail2Change}/>
                        </FormGroup>
                        <p style={{color:'red'}}>{error}</p>
                        <Button type="submit" style={{width:'100%'}} disabled={working} color="primary">Generar login</Button>
                    </Form>
                    <br/>
                    {/*<p style={{textAlign:'left'}}>
                        Se te enviará una liga a tu correo con la que podrás iniciar sesión. Si no tienes una cuenta
                        se te creará una de manera automática
                    </p>
                    <p style={{textAlign:'left'}}>
                        Al dar click en "Entrar", aceptas estar de acuerdo con los <a href="#">Términos y condiciones</a> del servicio
                    </p>*/}
                </div> 
            }                        
        </React.Fragment>
    )
}

export default Login