import React, {useState, useEffect} from 'react';
import {httpGet} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';

function EmailNoticeSPEI({isOpen, onClose}) {

    const [emailFacturacion, setEmailFacturacion] = useState('');

    useEffect(function() {
        async function getFacturaEmail() {
            try {
                const response = await httpGet('/invoiceData/getGlobalInvoiceEmail');
                if(response?.data.email) {
                    setEmailFacturacion(response.data.email);
                }
            } catch(e) {
            }
        }

        if(isOpen) {
            getFacturaEmail();
        }        

    }, [isOpen])

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>
                Email enviado
            </ModalHeader>
            <ModalBody>
                <p>
                    Se ha enviado un correo electronico con instrucciones al correo: <b>{emailFacturacion}</b>,
                    cuenta con tres dias para realizar su pago.
                    <br/>
                </p>
                <p>
                    <b>IMPORTANTE:</b> Una vez que haya realizado su pago, envíe un comprobante de transferencia a <b>ventas@firmamex.com</b>.
                    Su pago no se verá reflejado hasta que envíe su comprobante.

                </p>
            </ModalBody>
            <ModalFooter>
                <Button disable={!emailFacturacion} color="primary" onClick={onClose}>Cerrar</Button>
            </ModalFooter>
        </Modal>
    )
}

export default EmailNoticeSPEI