import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {FormGroup, Label, Input, Button} from 'reactstrap';
import SupportDataModal from '../supportData/SupportDataModal';

function SelectGlobalSupportData({disabled}) {
    
    const [supportDataList, setSupportDataList] = useState([]);
    const [supportModal, setSupportModal] = useState({
        open: false,
        initialState: null
    });
    const [valueSelected, setValueSelected] = useState();
    const [supportInfo, setSupportInfo] = useState({
        id: '',
        email: ''
    })

    const [activeSupportInfo, setActiveSupportInfo] = useState({
        id: ''
    })
    const [working, setWorking] = useState(false);
    
    const [supportChecked, setSupportChecked] = useState(false);
    const [noSupport, setNoSupport] = useState(true);
    const [autoSet, setAutoSet] = useState(false);
    const [noSupportList, setNoSupportList] = useState(true);

    useEffect(() => {        
        fetchSupportDataList(true);
    }, [])

    async function fetchSupportDataList(globalFetch) {
        setNoSupport(true);
        setWorking(true);
        try {
            const response = await httpGet(`/supportData/select`);
            if(response.data){
                setSupportDataList(response.data);

                if(response.data.length > 0){
                    setNoSupportList(false);
                } else {
                    setAutoSet(true);
                }

                if(globalFetch){
                    const currentValue = await httpGet(`/supportData/getGlobalSupport`);
                    if(currentValue.data && currentValue.data.id != null) {
                        setValueSelected(currentValue.data.id);
                        //onChange(currentValue.data);
                        setSupportInfo(currentValue.data);
                        setActiveSupportInfo(currentValue.data)
                        setNoSupport(false);
                    } else {
                        setNoSupport(true);

                        if(response.data.length > 0){
                            const responseDef = await httpGet(`/invoiceData/${response.data[0].id}`);
                            setSupportInfo({...responseDef.data, working: false});
                            setValueSelected(response.data[0].id);
                        }
                    }
                }
                setSupportChecked(true);
            }   
        } catch(e) {
            console.error(e);
            setSupportDataList([]);
            setWorking(false);
        }
        setWorking(false);
    }

    function handleSelectChange(event) {
        setValueSelected(event.target.value);
        //onChange(event.target.value);
        async function fetchSupportData() {
            const response = await httpGet(`/supportData/${event.target.value}`);
                setSupportInfo({...response.data, working: false});
            }
        fetchSupportData();
    }

    function handleSupportDataSaved(dataSaved) {
        setSupportModal({...supportModal, open:false})
        fetchSupportDataList(false);
        setValueSelected(dataSaved.id);
        setSupportInfo(dataSaved);
        if(dataSaved.id === activeSupportInfo.id) {
            setActiveSupportInfo(dataSaved);
        }
        if(autoSet) {
            async function updateSupport(){
                await httpPost(`/supportData/updateSupportData`, {
                    supportContactId: dataSaved.id
                });
                setActiveSupportInfo(dataSaved);
            };
            updateSupport();
            setAutoSet(false);
        }
        setNoSupport(false);
    }

    function handleEdit(iData) {
        return function() {
            setSupportModal({initialState: iData, open: true})            
        }
    }

    function handleUpdateSupport() {
        async function updateSupport(){
            await httpPost(`/supportData/updateSupportData`, {
                supportContactId: valueSelected
            });
            setNoSupport(false);
            setActiveSupportInfo(supportInfo);
        };
        updateSupport();
    }

    function handleAddSupport() {
        setSupportModal({initialState: null, open: true})
    }

    return (
        <React.Fragment>
            <br/>
            <h4>Contacto de soporte</h4>
            <br/>
            {
                supportChecked ? <React.Fragment>
                    {
                        !noSupportList ? <React.Fragment>
                            {
                                noSupport ? <React.Fragment>
                                <span style={{color: 'red', fontWeight:'bold'}}>No cuentas con un contacto de soporte activo, favor de seleccionar uno.</span>
                                </React.Fragment> : <React.Fragment>
                                    <b>Contacto activo</b>
                                    <br/>
                                    <p>
                                        Nombre: {activeSupportInfo.supportName} <br/>
                                        Email: {activeSupportInfo.supportEmail} <br/>
                                        Teléfono: {activeSupportInfo.supportPhone} <br/>                    
                                    </p>  
                                </React.Fragment>
                            }
                            <br/>
                            <hr/>
                            <br/>
                            <h4>Cambiar contacto de soporte</h4>
                            <br/>
                            <FormGroup>
                                <div className="row justify-content-end">
                                    <Button disabled={working} style={{marginRight:'10px'}} color="primary" onClick={handleEdit(supportInfo)}>Editar este contacto</Button>
                                </div>
                                <br/>
                                <Input type="select" id="supportData" value={valueSelected}
                                    onChange={handleSelectChange}
                                    disabled={working}
                                    required>
                                    {
                                        supportDataList.map(i => (
                                            <option key={i.id} value={i.id}>{i.supportEmail} - {i.supportName}</option>
                                        )) 
                                    }
                                </Input>
                            </FormGroup>                            
                            {
                                supportInfo.id === activeSupportInfo.id ? <React.Fragment>
                                    <b>Este es el contacto activo</b>
                                </React.Fragment> : null
                            }
                            <p>
                                Nombre: {supportInfo.supportName} <br/>
                                Email: {supportInfo.supportEmail} <br/>
                                Teléfono: {supportInfo.supportPhone} <br/>                    
                            </p>
                            <div className="row justify-content-end">
                                <Button disabled={working} color="primary" onClick={handleUpdateSupport}>Actualizar mi contacto de soporte</Button>
                            </div>
                            <br/>   
                        </React.Fragment> : <React.Fragment>
                            <br/>
                            <span style={{color: 'red', fontWeight:'bold'}}>No cuentas con ningun contacto de soporte, favor de agregar uno.</span>
                        </React.Fragment>
                    }
                </React.Fragment> : null
            } 
            <hr/>
            <div className="row justify-content-center">
                <Button disabled={working} color="primary" onClick={handleAddSupport}>Agregar un nuevo contacto de soporte</Button>
            </div>   
            <br/>
            <SupportDataModal
                isOpen={supportModal.open}
                initialState={supportModal.initialState}
                onCancel={() => setSupportModal({initialState: null, open: false})}
                onSaved={handleSupportDataSaved}/>
        </React.Fragment>
    )

}

export default SelectGlobalSupportData