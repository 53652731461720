import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Alert} from 'reactstrap'

function PendingPaymentsAlert({count}) {

    const [msg, setMsg] = useState('');
    
    useEffect(() => {

        setMsg(count === 1 ?
            'Tienes un pago pendiente' :
            'Tienes más de un pago pendiente'
        )

    }, [count])

    return (
        <React.Fragment>
            {
                count > 0 ?
                <Link target="_blank" to={`/console/payments`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                    <Alert color="danger">                        
                        {msg}
                    </Alert>
                </Link> : null
            }
        </React.Fragment>
    )
}

export default PendingPaymentsAlert;
