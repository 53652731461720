import axios from 'axios';

const url = process.env.REACT_APP_HOST;

function backend(path) {
    return `${url}${path}`
}

function loginHeader() {
    if(axios.interceptors.request.handlers.length === 0 && localStorage.getItem('jwt')) {
        axios.interceptors.request.use(
          (config) => {
            let token = localStorage.getItem('jwt');

            if (token) {
              config.headers['Authorization'] = `Bearer ${ token }`;
            }

            return config;
          }, 

          (error) => {
            return Promise.reject(error);
          }
        );
    }
}

function httpGet(path) {
    console.log('get: ' + path)    
    loginHeader();
    return axios.get(backend(path));
}

async function httpPost(path, body) {
    console.log('post: ' + path)
    loginHeader();
    return axios.post(backend(path), body);
}

export  {
    backend,
    httpGet,
    httpPost
}