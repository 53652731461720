import React from 'react';

function Section({children, ...rest}) {
    return (
        <div className="Section" {...rest}>
            <div className="container">
                {children}
            </div>
        </div>
    )
}

export default Section