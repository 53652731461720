import React, {useState, useEffect} from 'react'
import {httpGet, httpPost} from '../shared/Config';
import {Button, Modal, ModalBody, ModalHeader, ModalFooter,
    Form, FormGroup, Label, Input, FormText} from 'reactstrap';
// import SelectPlan from '../shared/SelectPlan'
//import PlanSelectorWithDetail from '../shared/PlanSelectorWithDetail';
//import SelectInvoiceData from '../shared/SelectInvoiceData'
//import ContractNameInput from './ContractNameInput';
import SpeiInfoModal from '../shared/SpeiInfoModal';

/*const defaultData = {
    organizationName: '',
    contract: '',
    plan: 'PERSONAL'
}*/

function NewOrganization({history}) {

    //const [org, setOrg] = useState(defaultData)
    const [invoiceData, setInvoiceData] = useState({
        compania: '',
        emailCompania: '',
        rfc:'',
        razonSocial:'',
        postalCode:'',
        cfdi:'',
        regimenFiscal: '',
        nameFacturacion:'',
        emailFacturacion:'',
        telefonoFacturacion:'',
        nombreSoporte:'',
        emailSoporte:'',
        telefonoSoporte:''
    });
    const [working, setWorking] = useState(false);  
    const [showSpeiInfo, setShowSpeiInfo] = useState(false);




    const [regimenFiscalesSelect, setregimenFiscalesSelect] = useState();

    /*function handleTextChange(attr) {
        return function(event) {
            setOrg({...org, [attr]:event.target.value})
        }
    }*/

    function handleInvoiceDataChange(name) {
        return function(event) {
            setInvoiceData({...invoiceData, [name]:event.target.value})
        }
    }
    
    useEffect(() => {
        async function fetchRegimenFiscales() {
            try {
                const response = await httpGet(`/getRegimenFiscales`);
                setregimenFiscalesSelect(response.data.map((regimenFiscal) => <option key={regimenFiscal.id} value={regimenFiscal.id}>{regimenFiscal.data}</option>));
            } catch(e) {
            }     
            
        }
        fetchRegimenFiscales();
    }, [])


    /*function handlePlanChange(plan) {
        setOrg({...org, plan})
    }*/

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        /*if(!org.contract || org.contract === '') {
            return;
        }*/
        setWorking(true);
        try {
            const response = await httpPost('/companyData', {
                invoiceData
            });
            setWorking(false);
            if(response.data.success) {
                //history.push(`/console/orgs/${response.data.data.id}`)
                history.push(`/console`);
            } else {
                console.error(response.data.error);
            }
        } catch(e) {
            setWorking(false);
        }
    }

    return (
        <Form onSubmit={handleSubmit} className="container">
                <h3>Registro</h3>
                <br/>
                <p>Bienvenido, da de alta tu organización para comenzar a crear departamentos</p>
                <br/>
                <FormGroup>
                    <Label for="compania">Nombre de la compañia</Label>
                    <Input type="text" id="compania" value={invoiceData.compania} onChange={handleInvoiceDataChange('compania')} required/>
                </FormGroup>
                <p>El nombre de tu compañia sera utilizado como prefijo para los nombres de tus departamentos.</p>
                <br/>
                <FormGroup>
                    <Label for="emailCompania">Correo electrónico de la compañia</Label>
                    <Input type="email" id="emailCompania" value={invoiceData.emailCompania} onChange={handleInvoiceDataChange('emailCompania')} required/>
                </FormGroup>
                <h4>Datos de facturación</h4>
                <br/>
                <FormGroup>
                    <Label for="rfc">RFC</Label>
                    <Input type="text" id="rfc" value={invoiceData.rfc} onChange={handleInvoiceDataChange('rfc')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="razonSocial">Razón social</Label>
                    <Input type="text" id="razonSocial" value={invoiceData.razonSocial} onChange={handleInvoiceDataChange('razonSocial')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="postalCode">Código postal</Label>
                    <Input type="text" id="postalCode" value={invoiceData.postalCode} onChange={handleInvoiceDataChange('postalCode')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="CFDI">Uso del CFDI</Label>
                    <Input type="select" value={invoiceData.cfdi} onChange={handleInvoiceDataChange('cfdi')} required>
                        <option value=""></option>
                        <option value="Adquisición de mercancías">Adquisición de mercancías</option>
                        <option value="Devoluciones, descuentos o bonificaciones">Devoluciones, descuentos o bonificaciones</option>
                        <option value="Gastos en general">Gastos en general</option>
                        <option value="Construcciones">Construcciones</option>
                        <option value="Mobiliario y equipo de oficina por inversiones">Mobiliario y equipo de oficina por inversiones</option>
                        <option value="Equipo de transporte">Equipo de transporte</option>
                        <option value="Equipo de cómputo y accesorios">Equipo de cómputo y accesorios</option>
                        <option value="Dados, troqueles, moldes, matrices y herramental">Dados, troqueles, moldes, matrices y herramental</option>
                        <option value="Comunicaciones telefónicas">Comunicaciones telefónicas</option>
                        <option value="Comunicaciones satelitales">Comunicaciones satelitales</option>
                        <option value="Otra maquinaria y equipo">Otra maquinaria y equipo</option>
                        <option value="Honorarios médicos, dentales y gastos hospitalarios.">Honorarios médicos, dentales y gastos hospitalarios</option>
                        <option value="Gastos médicos por incapacidad o discapacidad">Gastos médicos por incapacidad o discapacidad</option>
                        <option value="Gastos funerales">Gastos funerales</option>
                        <option value="Donativos">Donativos</option>
                        <option value="Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)">Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)</option>
                        <option value="Aportaciones voluntarias al SAR">Aportaciones voluntarias al SAR</option>
                        <option value="Primas por seguros de gastos médicos.">Primas por seguros de gastos médicos</option>
                        <option value="Gastos de transportación escolar obligatoria.">Gastos de transportación escolar obligatoria</option>
                        <option value="Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones">Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones</option>
                        <option value="Pagos por servicios educativos (colegiaturas)">Pagos por servicios educativos (colegiaturas)</option>
                        <option value="Por definir">Por definir</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="regimenFiscal">Régimen fiscal</Label>
                    <Input id="regimenFiscal" type="select" value={invoiceData.regimenFiscal} onChange={handleInvoiceDataChange('regimenFiscal')} required>
                        <option value=""></option>
                        {regimenFiscalesSelect}
                    </Input>
                </FormGroup>
                <br/>
                <h4>Datos de contacto de facturación</h4>
                <br/>
                <FormGroup>
                    <Label for="nameFacturacion">Nombre contacto de facturación</Label> 
                    <Input type="text" id="nameFacturacion" value={invoiceData.nameFacturacion} onChange={handleInvoiceDataChange('nameFacturacion')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="emailFacturacion">Correo electrónico facturación</Label>
                    <Input type="email" id="emailFacturacion" value={invoiceData.emailFacturacion} onChange={handleInvoiceDataChange('emailFacturacion')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="telefonoFacturacion">Teléfono facturación</Label>
                    <Input type="number" id="telefonoFacturacion" value={invoiceData.telefonoFacturacion} onChange={handleInvoiceDataChange('telefonoFacturacion')} required/>
                </FormGroup>
                <p>El método de pago será por medio de transferencia electrónica (SPEI)</p>
                <p>
                    Después de generar la organización, tendrás 3 días hábiles para realizar la transferencia. 
                    <Button color="link" onClick={() => setShowSpeiInfo(true)}>Ver más información</Button>
                </p> 
                <br/>
                <h4>Datos de contacto de soporte</h4>
                <br/>
                <FormGroup>
                    <Label for="rfc">Nombre de contacto de soporte</Label>
                    <Input type="text" id="rfc" value={invoiceData.nombreSoporte} onChange={handleInvoiceDataChange('nombreSoporte')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="email">Correo electrónico de soporte</Label>
                    <Input type="email" id="email" value={invoiceData.emailSoporte} onChange={handleInvoiceDataChange('emailSoporte')} required/>
                </FormGroup>
                <FormGroup>
                    <Label for="telefonoSoporte">Teléfono soporte</Label>
                    <Input type="number" id="telefonoSoporte" value={invoiceData.telefonoSoporte} onChange={handleInvoiceDataChange('telefonoSoporte')} required/>
                </FormGroup>
            <div className="row align-items-center justify-content-end">
                <Button style={{margin:'10px'}} type="submit" disabled={working} color="primary">Guardar</Button>                    
                {/* <Button disabled={working} color="secondary" onClick={handleCancel}>Cancelar</Button> */}
            </div>                       
            <br/>
            <br/>
            <br/>
            <SpeiInfoModal isOpen={showSpeiInfo} onClose={() => setShowSpeiInfo(false)}/>
        </Form>
    )
}

export default NewOrganization;