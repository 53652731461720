import React, {useState, useEffect} from 'react';
import Section from '../shared/Section';
import Table from '../shared/Table';
import {httpGet} from '../shared/Config';
import MoneyFormat from '../shared/MoneyFormat'
import DateFormat from '../shared/DateFormat'
import moment from 'moment';
import { Collapse, Button, Card, FormGroup, Label, Input } from 'reactstrap';

function PackagesBoughtInfoListSection({status, title, desc, hideIfNoData, typeString}) {

    const [packages, setPackages] = useState([]);
    const [statusPackage, setStatusPackage] = useState(status);
    const [noPackages, setNoPackages] = useState('');

    useEffect(() => {
        setNoPackages('');
        async function fetchPackages() {
            const response = await httpGet(`/packages/${statusPackage}`);

            if(response.data.length > 0) {
                for(let pb of response.data) {
                    const expirationDate = new Date(pb.expirationDate);
                    const now = moment();
                    let result = moment(expirationDate).diff(now,'days')
                    pb.diff = result;
                }
                setPackages(response.data);
            }
            if(response.data.length === 0) {
                setPackages([]);
                setNoPackages("Usted no tiene ningun paquete.")
            }
        }

        fetchPackages();
    }, [statusPackage]);

    function handleTypeChange() {
        return function(event) {
            setStatusPackage(event.target.value);
        }
    }

    return (
        <Section>
            <h4>{title}</h4>
                {desc}
                <FormGroup>
                    <Label for="status">Estatus de paquete</Label>
                    <Input id="status" type="select" value={statusPackage} onChange={handleTypeChange()} required>
                        <option value="Todos">Todos</option>
                        <option value="Activos">Activos</option>
                        <option value="Expirados">Expirados</option>
                    </Input>
                </FormGroup>
                <br/>
                <Table collection={packages} noData="Usted no tiene ningun paquete en esta categoría.">
                    <thead>
                        <tr>
                            <th>Concepto</th>
                            <th>Fecha de entrega de paquete</th>
                            <th>Fecha expiracion de paquete</th>
                            <th>Metodo de compra</th>
                            <th>¿Expirado?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            packages.map((p,i) => (
                                <tr key={i}>
                                    <td>
                                        {
                                            p.buyType === "DOCUMENTS" ? <React.Fragment>
                                                {p.amountBought} documentos
                                            </React.Fragment> : null
                                        }
                                    </td>                                    
                                    <td><DateFormat>{p.payloadCompletionDate}</DateFormat></td>                                    
                                    <td><DateFormat>{p.expirationDate}</DateFormat> 
                                    {
                                        p.diff > 0 ? <React.Fragment>
                                            <div style={{left: '10px'}}>(en {p.diff} dias)</div>
                                        </React.Fragment> : null
                                    }</td>                                  
                                    <td>
                                        {
                                            p.purchaseType === "COMPLEMENTARY" ? <React.Fragment>
                                                Paquete complementario
                                            </React.Fragment> : <React.Fragment>{p.purchaseType}</React.Fragment>
                                        }
                                    </td>
                                    <td>
                                        {
                                            p.expired ? <React.Fragment>
                                                Si
                                            </React.Fragment> : <React.Fragment>
                                                No
                                            </React.Fragment>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
        </Section>
    );
}

export default PackagesBoughtInfoListSection;